// https://api.nanostore.sa-rnd.devguru.co/sgra/public/versions/1.0/#tag/Products/operation/store.getProducts

import { complaintMockPreview } from "mocks/complaintMockPreview";
import { productDetailsMock } from "mocks/productsDetailsMock";

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import {
  EComplaintPreview,
  EProducts,
  TComplaintPreview,
  TProduct
} from "../useGetComplaint/types";

import { EIdentifier, EProperty, TRequest, TResponse } from "./types";

const getProductsUrl = (
  storeId: TComplaintPreview[EComplaintPreview.STORE_ID]
) => `${API_ROOT}/stores/${storeId}/products`;

type TProps = {
  storeId: TComplaintPreview[EComplaintPreview.STORE_ID];
  productId: TProduct[EProducts.BARCODE];
};

const useGetProducts = ({ storeId, productId }: TProps) => {
  const fetch = useFetchRefactored<TRequest, TResponse>({
    url: getProductsUrl(storeId),
    method: "POST",
    body: {
      [EProperty.IDENTIFIERS]: [{ [EIdentifier.PRODUCT_ID]: productId }]
    }
  });

  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready

  return {
    data: complaintMockPreview[EComplaintPreview.PRODUCTS][0],
    isLoading: false,
    fetchData: () =>
      new Promise<{ data: TResponse }>(resolve =>
        resolve({
          data: {
            [EProperty.IDENTIFIER]: EIdentifier.PRODUCT_ID,
            [EProperty.RESULT]: { ...productDetailsMock }
          }
        })
      )
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useGetProducts;
