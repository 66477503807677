import { useEffect, useState } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";

import { useRoles } from "hooks/roles/useRoles";
import { useAuthentication } from "hooks/useAuthentication";
import { EComplaintPreview } from "pages/complaint/methods/useGetComplaint/types";
import useSendComplaintsRefound from "pages/complaint/methods/useSendComplaintsRefound/useSendComplaintsRefound";
import useSendComplaintsReview from "pages/complaint/methods/useSendComplaintsReview/useSendComplaintsReview";

import { TTabProps } from "../../ComplaintPreview";
import {
  EComplaintDetails,
  EProcess,
  TPayload
} from "../../methods/useSendComplaintsReview/types";

import ComplaintRefoundModal from "./actionModals/ComplaintRefoundModal/ComplaintRefoundModal";
import ComplaintReviewModal from "./actionModals/ComplaintReviewModal/ComplaintReviewModal";
import Fields from "./Fields";
import { mapper } from "./mappers";
import { getSchema } from "./validator";

type TProps = { refetchComplaint: () => void };

export function CorrectBill({
  complaint,
  refetchComplaint
}: TTabProps & TProps) {
  const { canAcceptComplaints } = useRoles();
  const [currentProcess, setCurrentProcess] = useState<EProcess>();
  const [initialValues, setInitialValues] = useState<any>(null);

  const toast = useToast();
  const { getUserCurrentData } = useAuthentication();

  const {
    [EComplaintPreview.ORDER_ID]: orderId,
    [EComplaintPreview.ID]: complaintId,
    [EComplaintPreview.PRODUCTS]: products
  } = complaint;

  const sendComplaintsReview = useSendComplaintsReview({
    orderId,
    complaintId
  });
  const sendComplaintsRefound = useSendComplaintsRefound({
    orderId,
    complaintId
  });

  const getInitialValues = async () => {
    // TODO: TBD if this is required type of ID
    const { homeAccountId: accountId } = await getUserCurrentData();

    setInitialValues({
      [EComplaintPreview.PRODUCTS]: products,
      [EComplaintDetails.REVIEWED_BY]: accountId
    });
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: TPayload) => {
    const mappedValues = mapper(values, currentProcess);
    let resp;

    if (currentProcess === EProcess.REFUND) {
      resp = await sendComplaintsRefound.fetchData(mappedValues);
    } else {
      resp = await sendComplaintsReview.fetchData(mappedValues);
    }

    if (!resp?.data || resp.error)
      return toast({
        title: "Error",
        description: "Wystąpił błąd",
        status: "error",
        duration: 9000,
        isClosable: true
      });

    toast({
      title: "Sukces",
      description: "",
      status: "success",
      duration: 9000,
      isClosable: true
    });
    refetchComplaint();
  };

  return (
    <Box>
      {!!initialValues && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={getSchema(currentProcess)}
        >
          <Form>
            <Fields storeId={complaint[EComplaintPreview.STORE_ID]} />
            {canAcceptComplaints && (
              <Flex gap="1rem">
                <ComplaintReviewModal
                  isLoading={sendComplaintsReview.isLoading}
                  setProcess={() => setCurrentProcess(EProcess.APPROVED)}
                />
                <ComplaintReviewModal
                  isRejected
                  isLoading={sendComplaintsReview.isLoading}
                  setProcess={() => setCurrentProcess(EProcess.REJECTED)}
                />
                <ComplaintRefoundModal
                  isLoading={sendComplaintsRefound.isLoading}
                  setProcess={() => setCurrentProcess(EProcess.REFUND)}
                />
              </Flex>
            )}
          </Form>
        </Formik>
      )}
    </Box>
  );
}
