import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useFormikContext } from "formik";

import {
  EComplaintPreview,
  EProducts,
  TProduct
} from "../../methods/useGetComplaint/types";

import { emptyProduct, headLabels } from "./consts";
import Row from "./Row";

type TProps = {
  storeId: string;
};

const Fields = ({ storeId }: TProps) => {
  const { values, setFieldValue } = useFormikContext<{
    [EComplaintPreview.PRODUCTS]: TProduct[];
  }>();

  const handleAddProduct = () => {
    setFieldValue(EComplaintPreview.PRODUCTS, [
      ...values[EComplaintPreview.PRODUCTS],
      emptyProduct
    ]);
  };

  return (
    <Flex direction="column">
      <TableContainer>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              {headLabels.map(label => (
                <Th key={label.key}>{label.value}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {values[EComplaintPreview.PRODUCTS]?.map((product, index) => (
              <Row
                key={`${product[EProducts.BARCODE]}`}
                {...{ index, product, storeId }}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Button
        onClick={handleAddProduct}
        leftIcon={<AddIcon />}
        marginBlock="1rem 2rem"
        alignSelf="flex-end"
      >
        Dodaj produkt
      </Button>
    </Flex>
  );
};

export default Fields;
