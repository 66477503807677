export const testIsTime = (value: string) =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
export const testIsDate = (value: string) =>
  /^([0-2]?[0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}$/.test(value);

export const formatFromUTCTimeToLocal = (utcTimeString: string) => {
  const date = new Date();

  const [utcHours, utcMinutes] = utcTimeString.split(":").map(Number);

  date.setUTCHours(utcHours, utcMinutes, 0, 0);

  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const timeFormatter = (isoString: string, shouldUseUTC?: boolean) => {
  if (!isoString) {
    return "";
  }

  let date;

  if (testIsTime(isoString)) {
    // Get UTC datetime from time string
    date = new Date();
    const [isoHours, isoMinutes] = isoString.split(":").map(Number);
    date.setHours(isoHours, isoMinutes, 0, 0);
  } else {
    date = new Date(isoString);
  }

  let hours;
  let minutes;

  if (shouldUseUTC) {
    hours = date.getUTCHours().toString().padStart(2, "0");
    minutes = date.getUTCMinutes().toString().padStart(2, "0");
  } else {
    hours = date.getHours().toString().padStart(2, "0");
    minutes = date.getMinutes().toString().padStart(2, "0");
  }

  return `${hours}:${minutes}`;
};

export const dateFormatter = (isoString: string) => {
  if (testIsDate(isoString)) return isoString;

  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
