import { Badge } from "@chakra-ui/react";

import { getBadgeProps } from "./helpers";

export type TProps = {
  status: string | null;
};

export function StatusBadge({ status }: TProps) {
  if (!status) return null;

  const badgeProps = getBadgeProps(status);

  return (
    <Badge {...badgeProps} fontWeight="normal">
      {status}
    </Badge>
  );
}
