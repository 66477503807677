// https://api.nanostore.sa-rnd.devguru.co/themis/public/versions/1.1/#operation/get-orders-complaints

import { complaintMockPreview } from "mocks/complaintMockPreview";

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";
import { TComplaintPreview } from "pages/complaint/methods/useGetComplaint/types";

const getComplaintUrl = (complaintId?: string) =>
  `${API_ROOT}/complaints/${complaintId}`;

type TProps = { complaintId?: string };

const useGetComplaint = ({ complaintId }: TProps) => {
  const fetch = useFetchRefactored<any, TComplaintPreview>({
    url: getComplaintUrl(complaintId)
  });

  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready

  return {
    data: complaintMockPreview,
    isLoading: false,
    fetchData: () => {}
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useGetComplaint;
