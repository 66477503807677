import { Checkbox } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

type TProps = {
  name: string;
  options: { value: string; label: string }[];
};

export function FieldCustomCheckboxGroup({ name, options }: TProps) {
  const { values, setFieldValue } = useFormikContext<{
    [key: string]: string[];
  }>();
  const getValue = (value: string) => values[name]?.includes(value);
  const handleChange = (value: string) => () => {
    if (getValue(value)) {
      setFieldValue(
        name,
        values[name].filter(val => val !== value)
      );
    } else {
      setFieldValue(name, [...values[name], value]);
    }
  };

  return (
    <>
      {options.map(({ value, label }) => (
        <FieldWrapper key={value} label={label} isCheckbox name={name}>
          <Checkbox
            isChecked={getValue(value)}
            onChange={handleChange(value)}
          />
        </FieldWrapper>
      ))}
    </>
  );
}
