import { Button, Flex, Text } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import { Trash2 } from "lucide-react";

import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { tileStyles } from "pages/store/styles";
import {
  ECardIssuerActions,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameters,
  EStore,
  TStore
} from "types/configFields";

import StoreChainField from "./StoreChainField";

type TProps = {
  index: number;
  onRemove: () => void;
};

const Action = ({ index, onRemove }: TProps) => {
  const { values } = useFormikContext<TStore>();
  const { isReadOnly } = useFieldGenerationContext();
  const actionKey = `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${index}`;
  const data = getIn(values, actionKey);

  const isBin = Object.keys(data).includes(
    EIssuerActionProperty.CARD_ISSUER_BIN
  );

  const isBrand = Object.keys(data).includes(
    EIssuerActionProperty.CARD_ISSUER_BRAND
  );

  const isOverridePreauth =
    getIn(
      values,
      `${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.TYPE}`
    ) === EIssuerActionType.OVERRIDE_PREAUTH;

  return (
    <Flex {...tileStyles}>
      <Text fontSize="1rem" fontWeight="bold" mb="1rem">
        {isBin && "BIN"}
        {isBrand && "Brand"}
      </Text>

      {isBin && (
        <StoreChainField
          isSimpleField
          fieldType={EFieldType.TEXT}
          name={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BIN}`}
          labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.CARD_ISSUER_BIN}`}
        />
      )}

      {isBrand && (
        <StoreChainField
          isSimpleField
          fieldType={EFieldType.TEXT}
          name={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BRAND}`}
          labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.CARD_ISSUER_BRAND}`}
        />
      )}

      <StoreChainField
        isSimpleField
        fieldType={EFieldType.SELECT}
        name={`${actionKey}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
        labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
        options={Object.values(EIssuerActionType).map(value => ({
          label: value,
          value
        }))}
      />

      {isOverridePreauth && (
        <StoreChainField
          isSimpleField
          fieldType={EFieldType.TEXT}
          name={`${actionKey}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
          labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
        />
      )}

      {!isReadOnly && (
        <Button
          onClick={onRemove}
          variant="ghost"
          colorScheme="red"
          color="red.dark"
          width="min-content"
          marginTop="auto"
          marginLeft="auto"
          leftIcon={<Trash2 size="1rem" />}
        >
          Usuń
        </Button>
      )}
    </Flex>
  );
};

export default Action;
