import { useMemo, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  useToast
} from "@chakra-ui/react";
import {
  StoreChainConfig,
  StoreChainConfigCreate
} from "api/common/storeChainConfig";
import { FailedFetchingAlert } from "components";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";

import { API_ROOT, commonToastSetup } from "consts";
import { Routes } from "routes";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import { useStoreChainContext } from "contexts/StoreChainContext";
import { useFetchRefactored } from "hooks/useFetchRefactored";
import { convertNullsToEmptyStrings } from "utils/convertNullsToEmptyStrings";

import SearchPanel from "./components/SearchPanel";
import Fields from "./Fields";
import { payloadMapper, responseMapper } from "./mappers";
import {
  requiredMessage,
  validationSchema
} from "./validationSchema/validationSchema";

const StoreChainEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const {
    storeChain,
    isLoading,
    error: errorGetChain
  } = useStoreChainContext();
  const { fetchData, isLoading: isLoadingEditChain } = useFetchRefactored<
    StoreChainConfigCreate,
    StoreChainConfig
  >();

  const [expandedIndices, setExpandedIndices] = useState<number[]>([0]);
  const parsedData = useMemo(
    () =>
      convertNullsToEmptyStrings(
        responseMapper(storeChain)
      ) as StoreChainConfigCreate,
    [storeChain]
  );

  const handleGoToError = () => {
    setExpandedIndices([0, 1, 2, 3]);

    setTimeout(() => {
      const errorMessage = document.querySelector('[class*="error-message"]');

      if (errorMessage) {
        errorMessage.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    }, 200);
  };

  const handleSubmit = async (
    values: StoreChainConfigCreate,
    formikHelpers: FormikHelpers<StoreChainConfigCreate>
  ) => {
    const chainId = values.chainId;

    try {
      const { data, error } = await fetchData({
        url: `${API_ROOT}/storeChains/${chainId}`,
        method: "PUT",
        body: payloadMapper(values)
      });
      if (data) {
        toast({
          title: "Zapisano!",
          description: "Edycja sieci zakończona powodzeniem.",
          status: "success",
          ...commonToastSetup
        });

        navigate(`${Routes.STORE_CHAINS}/`);
      }

      if (error) {
        if (!error.content) {
          toast({
            title: "Wystąpił błąd!",
            description: error.statusText,
            status: "error",
            ...commonToastSetup
          });
          return;
        } else {
          toast({
            title: "Wystąpił błąd!",
            render: () => (
              <Alert status="error" padding=".5rem 1rem">
                <Flex flexDir="column" alignItems="center">
                  <Flex>
                    <AlertIcon />
                    Formularz zawiera błędy.
                  </Flex>
                  {error.content.detail && (
                    <Button
                      onClick={handleGoToError}
                      variant="ghost"
                      colorScheme="red"
                    >
                      Sprawdź
                    </Button>
                  )}
                  {error.content.errorMessage && (
                    <pre>
                      {JSON.stringify(error.content.errorMessage, null, 2)}
                    </pre>
                  )}
                </Flex>
              </Alert>
            ),
            duration: 8000,
            isClosable: true
          });

          const errors: Record<string, any> = {};

          error.content.detail.forEach((error: any) => {
            const { loc, msg, type } = error;
            let currentLevel = errors;

            loc.forEach((key: any, index: number) => {
              if (index === loc.length - 1) {
                currentLevel[key] =
                  type === "type_error.none.not_allowed" ||
                  type === "value_error.missing"
                    ? requiredMessage
                    : msg;
              } else {
                if (!currentLevel[key]) {
                  currentLevel[key] = {};
                }
                currentLevel = currentLevel[key];
              }
            });
          });

          const finalErrors = errors.body || {};
          const plainObject = JSON.parse(JSON.stringify(finalErrors));
          formikHelpers.setErrors(plainObject);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return <CustomSpinner />;
  }

  if (errorGetChain) {
    return <FailedFetchingAlert message="Nieudane pobieranie sieci" />;
  }

  if (!storeChain) {
    return null;
  }

  return (
    <>
      <CustomBreadcrumbs />
      <BorderedBox padding={{ base: "1rem", lg: "1.5rem" }}>
        <Heading textAlign="center" fontSize="1.5rem">
          Edycja konfiguracji sieci: {storeChain.name}
        </Heading>
      </BorderedBox>
      <Box mt="1rem">
        <Flex
          maxHeight="74vh"
          overflow="auto"
          position="relative"
          id="storeChain-wrapper"
        >
          <SearchPanel setExpandedIndices={setExpandedIndices} />
          <Box w="80%">
            <Formik
              initialValues={parsedData}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
            >
              <Form>
                <Fields
                  isReadOnly={false}
                  isEdit
                  isLoading={isLoadingEditChain}
                  expandedIndices={expandedIndices}
                  setExpandedIndices={setExpandedIndices}
                />
              </Form>
            </Formik>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default StoreChainEdit;
