import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { Field, useField } from "formik";

export type CustomInputProps = {
  isSmallLabel?: boolean;
  isDisabled?: boolean;
  isMasked?: boolean;
  isTouched?: boolean;
  name: string;
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  type?: string;
};

export function CustomInput({
  isSmallLabel,
  isDisabled,
  isMasked,
  name,
  label,
  errorMessage,
  placeholder,
  type
}: CustomInputProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [, { touched: isTouched }] = useField(name);

  const toggleVisibility = () => setIsVisible(prevIsVisible => !prevIsVisible);

  return (
    <FormControl isInvalid={isTouched && !!errorMessage}>
      {label && (
        <FormLabel
          fontSize={isSmallLabel ? ".8rem" : "initial"}
          mb={isSmallLabel ? "0" : "initial"}
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Field
          type={type || (isMasked && !isVisible ? "password" : "text")}
          placeholder={placeholder}
          as={Input}
          name={name}
          disabled={isDisabled}
        />
        {isMasked && (
          <InputRightElement>
            <IconButton
              onClick={toggleVisibility}
              backgroundColor="gray.medium"
              icon={isVisible ? <ViewOffIcon /> : <ViewIcon />}
              variant="solid"
              h="2rem"
              w="2rem"
              aria-label="toggle"
            />
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
