import { Button, Flex, Text } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import { Trash2 } from "lucide-react";

import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { CustomInput } from "pages/appConfigKey/configKeysForm/components/CustomInput";
import StoreField from "pages/store/components/StoreField";
import {
  ECardIssuerActions,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameters,
  EStore,
  TStore
} from "types/configFields";

import { tileStyles } from "../../styles";

type TProps = {
  index: number;
  onRemove: () => void;
};

const Action = ({ index, onRemove }: TProps) => {
  const { values, errors } = useFormikContext<TStore>();
  const { isReadOnly } = useFieldGenerationContext();
  const actionKey = `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${index}`;

  const data = getIn(values, actionKey);
  const isBin = Object.keys(data).includes(
    EIssuerActionProperty.CARD_ISSUER_BIN
  );

  const isBrand = Object.keys(data).includes(
    EIssuerActionProperty.CARD_ISSUER_BRAND
  );

  const isOverridePreauth =
    getIn(
      values,
      `${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.TYPE}`
    ) === EIssuerActionType.OVERRIDE_PREAUTH;

  const renderBinField = () => {
    return isReadOnly ? (
      <StoreField
        fieldType={EFieldType.TEXT}
        name={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BIN}`}
        labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.CARD_ISSUER_BIN}`}
      />
    ) : (
      <CustomInput
        isSmallLabel
        key={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BIN}`}
        name={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BIN}`}
        label="Numer BIN issuera karty, którego dotyczy akcja"
        errorMessage={getIn(
          errors,
          `${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BIN}`
        )}
      />
    );
  };

  const renderBrandField = () => {
    return isReadOnly ? (
      <StoreField
        fieldType={EFieldType.TEXT}
        name={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BRAND}`}
        labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.CARD_ISSUER_BRAND}`}
      />
    ) : (
      <CustomInput
        isSmallLabel
        key={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BRAND}`}
        name={`${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BRAND}`}
        label="Brand issuera karty, którego dotyczy akcja"
        errorMessage={getIn(
          errors,
          `${actionKey}.${EIssuerActionProperty.CARD_ISSUER_BRAND}`
        )}
      />
    );
  };

  const renderPreauthField = () => {
    return isReadOnly ? (
      <StoreField
        fieldType={EFieldType.TEXT}
        name={`${actionKey}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
        labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
      />
    ) : (
      <CustomInput
        isSmallLabel
        type="number"
        key={`${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
        name={`${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
        label="Nowa kwota preautoryzacji w pomniejszej walucie"
        errorMessage={getIn(
          errors,
          `${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`
        )}
      />
    );
  };

  return (
    <Flex {...tileStyles}>
      <Text fontSize="1rem" fontWeight="bold" mb="1rem">
        {isBin && "BIN"}
        {isBrand && "Brand"}
      </Text>

      {isBin && renderBinField()}
      {isBrand && renderBrandField()}

      {isReadOnly ? (
        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${actionKey}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
          labelOverride={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
        />
      ) : (
        <FieldWrapper
          name={`${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.TYPE}`}
          label="Typ akcji"
        >
          <FieldCustomSelect
            name={`${actionKey}.${EIssuerActionProperty.ACTION}.${EIssuerActionProperty.TYPE}`}
            placeholder="wybierz"
            options={Object.values(EIssuerActionType).map(value => ({
              label: value,
              value
            }))}
          />
        </FieldWrapper>
      )}

      {isOverridePreauth && renderPreauthField()}

      {!isReadOnly && (
        <Button
          onClick={onRemove}
          variant="ghost"
          colorScheme="red"
          color="red.dark"
          width="min-content"
          marginTop="auto"
          marginLeft="auto"
          leftIcon={<Trash2 size="1rem" />}
        >
          Usuń
        </Button>
      )}
    </Flex>
  );
};

export default Action;
