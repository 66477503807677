import * as Yup from "yup";

import { EStoreService, EStoreServiceProperty } from "types/configFields";

const requiredMessage = "To pole nie może być puste";

export const servicesSchema = Yup.object().shape({
  [EStoreService.SHORTENER]: Yup.object().shape({
    [EStoreServiceProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EStoreServiceProperty.DOMAIN]: Yup.string().required(requiredMessage)
  }),
  [EStoreService.SYNERISE]: Yup.object().shape({
    [EStoreServiceProperty.SERVICE_KEY]: Yup.string().required(requiredMessage)
  }),
  [EStoreService.ERP]: Yup.object().shape({
    [EStoreServiceProperty.SERVICE_KEY]: Yup.string().required(requiredMessage)
  })
});
