import { mockComplaintsResponse } from "mocks/complaintsMocks";

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { TComplaintsResponse } from "./types";

const getComplaintsUrl = () => `${API_ROOT}/complaints`;

const useGetComplaints = () => {
  const fetch = useFetchRefactored<any, TComplaintsResponse>({
    url: getComplaintsUrl()
  });
  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready

  return {
    error: null,
    data: mockComplaintsResponse,
    isLoading: false,
    fetchData: () => {}
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useGetComplaints;
