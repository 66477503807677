import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link as ChakraLink
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { CustomSpinner } from "components/customSpinner/CustomSpinner";

import { TTabProps } from "../ComplaintPreview";
import { billTableHeadLabels } from "../constants";
import {
  EComplaintPreview,
  EPrice,
  EProducts
} from "../methods/useGetComplaint/types";

export function Bill({ complaint, isLoading }: TTabProps) {
  return (
    <Box>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <TableContainer>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                {billTableHeadLabels.map(label => (
                  <Th key={label.key}>{label.value}</Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {complaint[EComplaintPreview.PRODUCTS]?.map(product => (
                <Tr key={product[EProducts.BARCODE]}>
                  <Td key={product[EProducts.DISPLAY_NAME]}>
                    <ChakraLink
                      as={Link}
                      to={`/products/${product[EProducts.BARCODE]}`}
                      color="blue.600"
                    >
                      {product[EProducts.DISPLAY_NAME]}
                    </ChakraLink>
                  </Td>
                  <Td key={product[EProducts.BARCODE]}>
                    {product[EProducts.BARCODE]}
                  </Td>
                  <Td key={product[EProducts.PRICE][EPrice.TOTAL]}>
                    {product[EProducts.PRICE][EPrice.TOTAL]}
                  </Td>
                  <Td key={product[EProducts.PRICE][EPrice.CASH]}>
                    {product[EProducts.PRICE][EPrice.CASH]}
                  </Td>
                  <Td key={product[EProducts.PRICE][EPrice.LOYALTY_POINTS]}>
                    {product[EProducts.PRICE][EPrice.LOYALTY_POINTS]}
                  </Td>
                  <Td key={product[EProducts.QUANTITY]}>
                    {product[EProducts.QUANTITY]}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
