import { StateColorScheme } from "consts";

import { TPowerBouncerStatus } from "../types";

export type TStatus = {
  label: string;
  color: StateColorScheme;
};

export const powerBouncerStatusLabels: Record<TPowerBouncerStatus, TStatus> = {
  [TPowerBouncerStatus.ACTIVE]: {
    label: "Aktywny",
    color: StateColorScheme.OK
  },
  [TPowerBouncerStatus.MAINTENANCE]: {
    label: "Przerwa serwisowa",
    color: StateColorScheme.WARNING
  },
  [TPowerBouncerStatus.ALWAYS_OPEN]: {
    label: "Zawsze otwarte",
    color: StateColorScheme.OK
  }
};

export const UnknownPowerBouncerStatus: TStatus = {
  label: "Stan nieznany",
  color: StateColorScheme.OTHER
};
