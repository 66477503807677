import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";

import { Property } from "components/property";
import { labels } from "pages/complaint/constants";

import {
  EComplaintPreview,
  TComplaintPreview
} from "../../methods/useGetComplaint/types";

import { messageMapper } from "./messageMapper";

type TProps = {
  isLoading?: boolean;
  content: TComplaintPreview[EComplaintPreview.CONTENT];
};

const noContentStyle = {
  fontStyle: "italic",
  color: "gray.600"
};

const UserMessage = ({ isLoading, content }: TProps) => {
  const { products, missingProducts, receipt, message } =
    messageMapper(content);

  return (
    <Flex
      direction="column"
      gap="1rem"
      border="1px solid"
      borderColor="gray.400"
      padding="1rem"
      borderRadius="1rem"
    >
      <Text fontWeight="600">{labels[EComplaintPreview.CONTENT]}</Text>
      <Property label="Błędy do produktów" isLoading={isLoading}>
        {!!products.length ? (
          <TableContainer>
            <Table size="sm" marginBlock="1rem" maxWidth="60rem">
              <Thead>
                <Tr>
                  <Th>Id produktu</Th>
                  <Th>Treść</Th>
                </Tr>
              </Thead>
              <Tbody>
                {products.map(({ id, text }) => (
                  <Tr key={id}>
                    <Td>
                      <Text>{id}</Text>
                    </Td>
                    <Td>
                      <Text>{text}</Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Text as="span" {...noContentStyle}>
            nie zgłoszono
          </Text>
        )}
      </Property>

      <Property label="Brakujące produkty w rachunku" isLoading={isLoading}>
        <Text>
          {missingProducts || (
            <Text as="span" {...noContentStyle}>
              nie zgłoszono
            </Text>
          )}
        </Text>
      </Property>

      <Property label="Błąd do calego rachunku" isLoading={isLoading}>
        <Text>
          {receipt || (
            <Text as="span" {...noContentStyle}>
              nie zgłoszono
            </Text>
          )}
        </Text>
      </Property>

      <Property label="Wiadomość od klienta" isLoading={isLoading}>
        <Text>
          {message || (
            <Text as="span" {...noContentStyle}>
              nie zgłoszono
            </Text>
          )}
        </Text>
      </Property>
    </Flex>
  );
};

export default UserMessage;
