// https://api.nanostore.sa-rnd.devguru.co/order-service/public/versions/1.2.5/#operation/getUserOrderDetails

import { userOrderDetailsMock } from "mocks/userOrderDetailsMock";

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";
import {
  EComplaintPreview,
  TComplaintPreview
} from "pages/complaint/methods/useGetComplaint/types";

import { TUserOrderDetails } from "./types";

const getUserOrderDetailsUrl = (
  userId: TComplaintPreview[EComplaintPreview.USER_ID],
  orderId: TComplaintPreview[EComplaintPreview.ORDER_ID]
) => `${API_ROOT}/users/${userId}/orders/${orderId}`;

type TProps = {
  userId: TComplaintPreview[EComplaintPreview.USER_ID];
  orderId: TComplaintPreview[EComplaintPreview.ORDER_ID];
};

const useGetUserOrderDetails = ({ userId, orderId }: TProps) => {
  const fetch = useFetchRefactored<any, TUserOrderDetails>({
    url: getUserOrderDetailsUrl(userId, orderId)
  });

  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready

  return {
    data: userOrderDetailsMock,
    isLoading: false,
    fetchData: () => {}
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useGetUserOrderDetails;
