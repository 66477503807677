import { Box, Link as ChakraLink } from "@chakra-ui/react";
import { LinkIcon } from "lucide-react";

export function PriceList() {
  return (
    <Box>
      <ChakraLink
        target="_blank"
        href="https://app.powerbi.com/groups/me/apps/d754b28f-2386-456f-ad4e-c394647088c1/reports/b6871481-4516-4c78-84bf-cf0feae5acaf/ReportSection?ctid=153ec1e0-e0e7-4a31-82a0-d1f594ce8825&experience=power-bi"
        color="blue.600"
        display="flex"
        alignItems="center"
        gap="1rem"
      >
        <LinkIcon size="1rem" />
        Raport Power BI
      </ChakraLink>
    </Box>
  );
}
