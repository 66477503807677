import { useCallback, useState } from "react";
import { Badge, Flex, Text } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";

import FormField, { EFieldType } from "components/formField/FormField";
import { getDisplayValue } from "components/formField/utils";
import { Property } from "components/property";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { useStoreContext } from "contexts/StoreContext";
import { responseMapper } from "pages/storeChain/mappers";
import { EStore, TStore } from "types/configFields";
import { timeFormatter } from "utils/dateTime";

interface IStoreField {
  name: string;
  labelOverride?: string;
  fieldType: EFieldType;
  isDisabled?: boolean;
  isSimpleField?: boolean;
  isLoading?: boolean;
  options?: { value: string; label: string }[];
}

const StoreField = ({
  isSimpleField = false,
  isDisabled = false,
  isLoading,
  name,
  labelOverride,
  fieldType = EFieldType.TEXT,
  options = []
}: IStoreField) => {
  const fieldProps = {
    name,
    labelOverride,
    fieldType,
    options,
    isDisabled,
    isLoading
  };

  const { isReadOnly, labels, isCreate, isEdit } = useFieldGenerationContext();
  const { values, touched, setFieldValue, initialValues } =
    useFormikContext<TStore>();
  const { storeChains } = useStoreContext();
  const value = getIn(values, name);
  const initialValue = getIn(initialValues, name);

  const formDisplayValue = getDisplayValue(value, fieldType);
  const storeChainId = getIn(values, EStore.CHAIN_ID);
  const currentChain =
    storeChains.find(chain => chain.chainId === storeChainId) || null;
  const mappedChain = responseMapper(currentChain);
  const storeChainValue = getIn(mappedChain, name);
  const storeChainDisplayValue = getDisplayValue(storeChainValue, fieldType);

  const isTouched = getIn(touched, name);
  const isValueDefined = value !== undefined && value !== null && value !== "";
  const isStoreChainValueDefined =
    storeChainValue !== undefined &&
    storeChainValue !== null &&
    storeChainValue !== "";

  const [shouldUseChainValue, setShouldUseChainValue] = useState(false);
  const [lockedValue, setLockedValue] = useState<string>(value);

  const getActiveValue = (): "store" | "chain" | undefined => {
    if (shouldUseChainValue) {
      return "chain";
    }

    // Store config VIEW
    if (isReadOnly) {
      if (isValueDefined) {
        return "store";
      }

      if (isStoreChainValueDefined) {
        return "chain";
      }

      return undefined;
    }

    // Store config CREATE
    if (isCreate) {
      const hasValueChanged = value !== initialValue;

      if (!isValueDefined && isStoreChainValueDefined) {
        return "chain";
      }

      if (isValueDefined) {
        if (isStoreChainValueDefined) {
          return isTouched && hasValueChanged ? "store" : "chain";
        } else {
          return isTouched && hasValueChanged ? "store" : undefined;
        }
      }
    }

    // Store config EDIT
    if (isEdit) {
      if (isValueDefined) {
        return "store";
      } else {
        if (isStoreChainValueDefined) {
          return "chain";
        } else {
          return undefined;
        }
      }
    }

    return undefined;
  };

  const getStoreBadgeClickability = useCallback(() => {
    if (isReadOnly) {
      return "default";
    }

    if (isValueDefined && isTouched && !shouldUseChainValue) {
      return "not-allowed";
    }

    return "pointer";
  }, [isReadOnly, isTouched, isValueDefined, shouldUseChainValue]);

  const getStoreChainBadgeClickability = useCallback(() => {
    if (isReadOnly) {
      return "default";
    }

    if (!isStoreChainValueDefined) {
      return "not-allowed";
    }

    if (!shouldUseChainValue && isValueDefined) {
      return "pointer";
    }

    if (isStoreChainValueDefined) {
      if ((isTouched && shouldUseChainValue) || !isTouched) {
        return "not-allowed";
      }
    }

    return "pointer";
  }, [
    isReadOnly,
    isStoreChainValueDefined,
    isTouched,
    isValueDefined,
    shouldUseChainValue
  ]);

  const handleStoreChainBadgeClick = useCallback(() => {
    if (getStoreChainBadgeClickability() === "pointer") {
      const lockedValue =
        fieldType === EFieldType.TIME ? timeFormatter(value) : value;

      setLockedValue(lockedValue);
      setFieldValue(name, undefined);
      setShouldUseChainValue(true);
    }
  }, [fieldType, getStoreChainBadgeClickability, name, setFieldValue, value]);

  return (
    <Flex direction="column" gap=".5rem">
      <Flex gap=".5rem" alignItems="flex-end">
        {!isSimpleField && (
          <Badge
            fontSize=".6rem"
            variant="solid"
            minWidth="2.5rem"
            colorScheme={getActiveValue() === "store" ? "green" : undefined}
            cursor={getStoreBadgeClickability()}
            height="min-content"
            onClick={() => {
              if (getStoreBadgeClickability() === "pointer") {
                setShouldUseChainValue(false);
                setFieldValue(name, lockedValue);
              }
            }}
          >
            Sklep
          </Badge>
        )}
        {isReadOnly || shouldUseChainValue ? (
          <Property
            id={name}
            wordBreak="break-all"
            isDisabled={shouldUseChainValue}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
          >
            {shouldUseChainValue
              ? getDisplayValue(lockedValue, fieldType)
              : formDisplayValue || (
                  <Text as="span" color="gray.500" fontStyle="italic">
                    brak danych
                  </Text>
                )}
          </Property>
        ) : (
          <FormField {...fieldProps} />
        )}
      </Flex>

      {!isSimpleField && (
        <Flex fontSize=".75rem" gap=".5rem" alignItems="center">
          <Badge
            fontSize=".6rem"
            variant="solid"
            minWidth="2.5rem"
            backgroundColor={
              getActiveValue() === "chain" ? "green.700" : undefined
            }
            cursor={getStoreChainBadgeClickability()}
            onClick={handleStoreChainBadgeClick}
          >
            Sieć
          </Badge>
          <Text
            color={shouldUseChainValue ? "green.700" : "gray.600"}
            wordBreak="break-all"
            fontStyle={storeChainDisplayValue ? "normal" : "italic"}
          >
            {storeChainDisplayValue || "brak danych"}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default StoreField;
