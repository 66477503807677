import { Flex } from "@chakra-ui/react";
import { StoreChainConfigCreate } from "api/common/storeChainConfig";
import { useFormikContext } from "formik";

import { EFieldType } from "components/formField/FormField";
import { componentTypesOptions } from "pages/store/consts";
import { cardStyles } from "pages/store/styles";
import {
  EComponent,
  EComponentProperty,
  EComponentType,
  EStore
} from "types/configFields";

import MonitorFields from "./MonitorFields";
import StoreChainField from "./StoreChainField";

const Pos = () => {
  const { values } = useFormikContext<StoreChainConfigCreate>();

  const keyPart = `${EStore.COMPONENTS}.${EComponent.POS}`;
  const currentType =
    values[EStore.COMPONENTS]?.[EComponent.POS][EComponentProperty.TYPE];
  const isPosService = (currentType as any) === EComponentType.POS_SERVICE;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreChainField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.SERVICE_KEY}`}
        />

        <StoreChainField
          fieldType={EFieldType.SELECT}
          name={`${keyPart}.${EComponentProperty.TYPE}`}
          options={componentTypesOptions}
        />
      </Flex>

      {isPosService && (
        <Flex w="full" gap="1rem">
          <MonitorFields
            title="Konfiguracja monitora dostępności sklepu - POS"
            monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
          />
          <MonitorFields
            title="Konfiguracja monitora stanu papieru w drukarce (tylko dla tradycyjnego POS-a Żabka)"
            monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.PAPER_STATE}`}
          />
        </Flex>
      )}
    </>
  );
};

export default Pos;
