import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import { Property } from "components/property";

import { TTabProps } from "../ComplaintPreview";
import { labels } from "../constants";
import { EClient, EComplaintPreview } from "../methods/useGetComplaint/types";
import { EComplaintsStats } from "../methods/useGetComplaintStats/types";
import useGetComplaintsStats from "../methods/useGetComplaintStats/useGetComplaintsStats";

export function ClientData({ complaint, isLoading }: TTabProps) {
  const complaintsStats = useGetComplaintsStats(
    complaint[EComplaintPreview.ID]
  );

  useEffect(() => {
    complaintsStats.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" gap="1rem">
      <Property
        isLoading={isLoading}
        label={labels[`${EComplaintPreview.CLIENT}.${EClient.LOYAL_ID}`]}
        isLink
        href={`/clients/${complaint[EComplaintPreview.CLIENT][EClient.LOYAL_ID]}`}
      >
        {complaint[EComplaintPreview.CLIENT][EClient.LOYAL_ID]}
      </Property>

      <Property
        isLoading={isLoading}
        label={labels[`${EComplaintPreview.CLIENT}.${EClient.PHONE}`]}
      >
        {complaint[EComplaintPreview.CLIENT][EClient.PHONE]}
      </Property>

      <Property
        isLoading={isLoading}
        label={labels[`${EComplaintPreview.CLIENT}.${EClient.EMAIL}`]}
      >
        {complaint[EComplaintPreview.CLIENT][EClient.EMAIL]}
      </Property>

      <Property
        isLoading={isLoading}
        label={labels[`${EComplaintPreview.CLIENT}.${EClient.CRM_ID}`]}
      >
        {complaint[EComplaintPreview.CLIENT][EClient.CRM_ID]}
      </Property>

      <Property
        isLoading={complaintsStats.isLoading}
        label={labels[`${EComplaintPreview.CLIENT}.${EComplaintsStats.COUNT}`]}
      >
        {complaintsStats.data?.[EComplaintsStats.COUNT]}
      </Property>
    </Flex>
  );
}
