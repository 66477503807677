import {
  ECLientPriority,
  EComponent,
  EComponentProperty,
  EDay,
  EMonitor,
  EParameterProperty,
  EParameterPropertySms,
  EParameters,
  EProperty,
  ESchedule,
  EStore,
  EStoreService,
  EStoreServiceProperty
} from "types/configFields";

const MONITORS_PER_COMPONENT = {
  [EComponent.AIFI]: [EComponentProperty.STATE],
  [EComponent.ADYEN]: [EComponentProperty.AVAILABILITY],
  [EComponent.INFOKIOSK]: [EComponentProperty.AVAILABILITY],
  [EComponent.POS]: [
    EComponentProperty.AVAILABILITY,
    EComponentProperty.PAPER_STATE
  ],
  [EComponent.POWER_BOUNCER]: [
    EComponentProperty.AVAILABILITY,
    EComponentProperty.QR_READER_AVAILABILITY
  ]
};

const generateMonitorLabels = () => {
  const baseLabels = Object.values(EComponent)
    .map(
      componentName =>
        `${EStore.COMPONENTS}.${componentName}.${EComponentProperty.MONITORS}`
    )
    .map(componentKey => {
      const componentName = componentKey.split(".")[1] as EComponent;
      const monitorNames = MONITORS_PER_COMPONENT[componentName];

      return monitorNames.map(baseName => `${componentKey}.${baseName}`);
    })
    .flat();

  const monitorsLabels = baseLabels.reduce((acc, curr) => {
    return {
      ...acc,
      [`${curr}.${EMonitor.IS_ENABLED}`]: "Czy monitor jest aktywny",
      [`${curr}.${EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS}`]:
        "Czy monitor jest wyłączony po za godzinami pracy sklepu",
      [`${curr}.${EMonitor.IS_ACTIVE}`]: "Czy akcja jest aktywna"
    };
  }, {});
  const actionsLabels = baseLabels.reduce((acc, curr) => {
    return {
      ...acc,
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.CLOSE_THRESHOLD}`]:
        "Wartość graniczna procentowej niedostępności komponentu w danym okresie (zdefiniowanego przez pole close_period), warunkująca zamknięcie sklepu. (%)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.CLOSE_PERIOD}`]:
        "Okres weryfikacji stanu komponentu, będący podstawą do zamknięcia sklepu. (s)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.OPEN_THRESHOLD}`]:
        "Wartość graniczna procentowej dostępności komponentu w danym okresie (zdefiniowanego przez pole open_period), warunkująca otwarcie sklepu. (%)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.OPEN_PERIOD}`]:
        "Okres weryfikacji stanu komponentu, będący podstawą do otwarcia sklepu. (s)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.IS_ACTIVE}`]:
        "Czy akcja jest aktywna"
    };
  }, {});

  return { ...monitorsLabels, ...actionsLabels };
};

const generateHoursLabels = () => {
  // `${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}.${day}`
  const days = Object.values(EDay);

  const baseLabels = days.map(
    day => `${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}.${day}`
  );

  const hoursLabels = baseLabels.reduce((acc, curr) => {
    return {
      ...acc,
      [`${curr}.${EProperty.OPENS_AT}`]: "otwarcie",
      [`${curr}.${EProperty.CLOSES_AT}`]: "zamknięcie"
    };
  }, {});

  return hoursLabels;
};

export const label = {
  // general
  [EStore.STORE_ID]: "Identyfikator sklepu",
  [EStore.CHAIN_ID]: "Identyfikator sieci ",
  [EStore.CHAIN_NAME]: "Nazwa sieci",
  [EStore.EXTERNAL_STORE_ID]:
    "Zewnętrzny identyfikator sklepu nadawany przez klienta biznesowego",
  [EStore.NAME]: "Nazwa sklepu",
  [EStore.TYPE]: "Typ sklepu",
  [EStore.STATE]: "Status sklepu",
  [EStore.ADDRESS]: "Adres sklepu",
  [EStore.MANAGER_EMAIL]:
    "Adres email managera sklepu, na który są wysyłane powiadomienia i raporty",

  // parameters
  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.DOMAIN}`]:
    "Domena, pod którą udostępniony jest dokument potwierdzający dokonanie zakupów",
  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.TYPE}`]:
    "Typ dokumentu potwierdzającego dokonanie zakupów",
  [`${EStore.PARAMETERS}.${EParameters.DEFAULT_LANGUAGE}`]:
    "Domyślny język wykorzystywany do komunikacji z klientem",
  [`${EStore.PARAMETERS}.${EParameters.COUNTRY_CODE}`]:
    "Kod kraju działania sieci sklepów",
  [`${EStore.PARAMETERS}.${EParameters.DEFAULT_PREAUTH_AMOUNT}`]:
    "Domyślna kwota preautoryzacji w pomniejszej walucie (np grosze, eurocenty itp)",
  [`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`]:
    "Flaga sterująca włączeniem / wyłączeniem mechanizmu preautoryzacji dla wejść “na aplikację”",
  [`${EStore.PARAMETERS}.${EParameters.CURRENCY}`]: "Waluta obsługująca sklepy",
  [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS}`]:
    "Flaga określająca czy aktywacja konta (w procesie na kartę) jest niezbędna dla wszystkich istniejących kont klientów",
  [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_NEW_CLIENTS}`]:
    "Flaga określająca czy aktywacja konta (w procesie na kartę) jest niezbędna dla nowo utworzonych kont klientów",
  // -- sms
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.WELCOME_SMS}`]:
    "powitalnej (po utworzeniu użytkownika)",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.RECEIPT_SMS}`]:
    "z linkiem do paragonu",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.EXIT_SMS}`]:
    "po wyjściu klienta",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_FAILED_SMS}`]:
    "o nieudanej płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PBL_REQUESTED_SMS}`]:
    "z linkiem do płatności (w sytuacji ręcznego wymuszenia wysłania linku przez klienta)",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.ACTIVATION_SMS}`]:
    "z kodem aktywacyjnym",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.AUTO_RESCUE_INIT_SMS}`]:
    "o rozpoczęciu procesu AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS}`]:
    "o pierwszej próbie AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS}`]:
    "o finalnej próbie AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER1_SMS}`]:
    "z pierwszym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER2_SMS}`]:
    "z drugim przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER3_SMS}`]:
    "z trzecim przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER4_SMS}`]:
    "z czwartym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER5_SMS}`]:
    "z piątym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.IMPORT_IMAGES_FROM_PIM}`]:
    "Flaga określająca czy podczas importu produktów należy dokonać jednoczesnego importu obrazów z usługi PIM Service",
  [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.AIFI_IMPORT_ENABLED}`]:
    "Flaga określająca czy w ramach importu produktów następuje import do usługi AiFi.",

  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.ORDERS_RULES}.0.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.ORDERS_RULES}.0.${ECLientPriority.ORDERS_THRESHOLD}`]:
    "Graniczna liczba zakupów klienta, do której klient klasyfikuje się do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}.0.${ECLientPriority.STARTS_AT}`]:
    "Czas rozpoczęcia okresu (każdego dnia), w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}.0.${ECLientPriority.ENDS_AT}`]:
    "Czas zakończenia okresu (każdego dnia), w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}.0.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.STARTS_AT}`]:
    "Dokładny dzień i czas rozpoczęcia okresu, w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.ENDS_AT}`]:
    "Dokładny dzień i czas zakończenia okresu, w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}.${ECLientPriority.ENABLED}`]:
    "Flaga określająca czy reguła nadawania priorytetów dla klientów VIP jest włączona lub nie",

  [`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.SERVICE_KEY}`]:
    "service key",
  [`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.STORE_ID}`]:
    "Identyfikator sklepu w systemie ERP",
  [`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.IS_ENABLED}`]:
    "czy usługa jest włączona",
  [`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}.${EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL}`]:
    "Adres email pracownika sklepu odpowiedzialnego za weryfikację klienta (wymagane dla sklepu hybrydowego).",
  [`${EStore.SERVICES}.${EStoreService.SHORTENER}.${EStoreServiceProperty.DOMAIN}`]:
    "domain",
  [`${EStore.SERVICES}.${EStoreService.SHORTENER}.${EStoreServiceProperty.SERVICE_KEY}`]:
    "service key",
  [`${EStore.SERVICES}.${EStoreService.SYNERISE}.${EStoreServiceProperty.SERVICE_KEY}`]:
    "service key",
  [`${EStore.SERVICES}.${EStoreService.SYNERISE}.${EStoreServiceProperty.SUPER_LOGIN_SERVICE_KEY}`]:
    "super login service key",

  // components
  [`${EStore.COMPONENTS}.${EComponent.AIFI}.${EComponentProperty.AIFI_ID}`]:
    "Identyfikator sklepu w systemie AiFi",
  [`${EStore.COMPONENTS}.${EComponent.AIFI}.${EComponentProperty.ENTRY_ID}`]:
    "Identyfikator wejścia do sklepu",
  [`${EStore.COMPONENTS}.${EComponent.ADYEN}.${EComponentProperty.TERMINAL_ID}`]:
    "Identyfikator terminalu Adyena",
  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}.${EComponentProperty.SCOPE}`]:
    "Identyfikator infokiosku w systemie Nanovo",
  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi",
  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}.${EComponentProperty.HEARTBEAT_THRESHOLD}`]:
    "Czas graniczny dostarczenia heartbeatów przez infokiosk. W przypadku przekroczenia tego czasu uznaje się infokiosk jako niedziałający (s)",

  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.TYPE}`]:
    "Typ systemu POS",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.WORKER_ID}`]:
    "Identyfikator instancji workera odpowiedzialnego za przetwarzanie transakcji dla tego sklepu",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_IP_ADDRESS}`]:
    "Adres IP do odpowiadającego POS-a",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_PORT}`]:
    "Port na który należy wysyłać żądania",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_USERNAME}`]:
    "Username używany do autentykacji z REST POS",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_PASSWORD}`]:
    "Hasło używane do autentykacji z REST POS",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.OUTAGE_THRESHOLD}`]:
    "Liczba wystąpienia awarii “pod rząd” systemu POS, po której POS uznawany jest jako niedziałający",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.NO_OUTAGE_THRESHOLD}`]:
    "Liczba braków awarii “pod rząd” systemu POS, po której POS uznawany jest jako działający",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.IPOS}.${EComponentProperty.TRONITAG_ID}`]:
    "Identyfikator sklepu w TroniTag",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.IPOS}.${EComponentProperty.FAKTUROWNIA_ID}`]:
    "Identyfikator sklepu w Fakturownia",

  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.TOKEN}`]:
    "identyfikator / token instancji aplikacji power-bouncer przypisanej do danego sklepu. Wartość jest ustalana podczas rejestracji aplikacji i nie powinna być podawana ręcznie.",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.HELP_LINK}`]:
    "Link do pomocy",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.HEARTBEAT_THRESHOLD}`]:
    "Czas graniczny (w sekundach) dostarczenia heartbeatów przez aplikację power-bouncer. W przypadku przekroczenia tego czasu uznaje się aplikację jako niedziałającą.",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.IO_CONTROLLER}.${EComponentProperty.SERVICE_KEY}`]:
    "Konfiguracja kontrolera IO odpowiedzialnego za obsługę elektrozamka i czujników drzwi lodówki",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.AGE_ESTIMATION}.${EComponentProperty.MIN_ADULT_AGE}`]:
    "Konfiguracja estymacji wieku w oparciu o skan twarzy",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.STATUS}`]:
    "Status",

  ...generateMonitorLabels(),
  ...generateHoursLabels(),

  [`${EStore.OPENING_SCHEDULE}.${ESchedule.IS_ENABLED}`]:
    "Flaga określająca czy harmonogram dla sklepu jest włączony lub nie",

  [`day.${EDay.MON}`]: "Poniedziałek",
  [`day.${EDay.TUE}`]: "Wtorek",
  [`day.${EDay.WED}`]: "Środa",
  [`day.${EDay.THU}`]: "Czwartek",
  [`day.${EDay.FRI}`]: "Piątek",
  [`day.${EDay.SAT}`]: "Sobota",
  [`day.${EDay.SUN}`]: "Niedziela"
};

export const headers = {
  general: "Informacje ogólne",

  [EStore.PARAMETERS]: "Parametry konfiguracyjne sklepu",
  [EStore.SERVICES]: "Dane konfiguracyjne usług zewnętrznych",
  [EStore.COMPONENTS]: "Konfiguracja komponentów w sklepie",
  [EStore.OPENING_SCHEDULE]: "Harmonogram otwarcia sklepu",

  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`]:
    "Konfiguracja dotycząca dokumentu potwierdzającego dokonanie zakupów, wysyłanego do klienta",
  [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`]:
    "Dane dotyczące aktywacji konta (w procesie na kartę)",
  [`${EStore.PARAMETERS}.${EParameters.SMS}`]:
    "Konfiguracja wysyłki wiadomości SMS w dla danego sklepu (dla procesu 'na kartę')",
  [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`]:
    "Konfiguracja importu produktów",
  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`]:
    "Lista akcji dla konkretnych issuerów kart → w ramach schematu wejścia 'na kartę'",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]:
    "Dane konfiguracyjne systemu priorytetowego traktowania klientów",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.ORDERS_RULES}`]:
    "Dane konfiguracyjne reguły nadawania priorytetów dla klientów nieprzekraczających określonej granicznej ilości zakupów",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}`]:
    "Dane konfiguracyjne reguł nadawania priorytetów dla klientów w konkretnych godzinach (niezależnie od dnia)",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}`]:
    "Dane konfiguracyjne reguł nadawania priorytetów dla klientów dla konkretnego pojedynczego okresu czasu",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}`]:
    "Dane konfiguracyjne reguł nadawania priorytetów dla klientów w konkretnym sklepie (niezależnie od okresu)",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`]:
    "Dane konfiguracyjne reguły nadawania priorytetów dla klientów VIP",

  [`${EStore.SERVICES}.${EStoreService.ERP}`]: "ERP",
  [`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}`]: "Nanomonitor",
  [`${EStore.SERVICES}.${EStoreService.SHORTENER}`]: "Shortener",
  [`${EStore.SERVICES}.${EStoreService.SYNERISE}`]: "Synerise",

  [`${EStore.COMPONENTS}.${EComponent.AIFI}`]: "Aifi",
  [`${EStore.COMPONENTS}.${EComponent.ADYEN}`]: "Adyen",
  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]: "Infokiosk",
  [`${EStore.COMPONENTS}.${EComponent.POS}`]: "POS",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]: "Power bouncer",

  [`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`]:
    "Bazowy tygodniowy harmonogram",
  [`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`]:
    "Wyjątki w harmonogramie"
};
