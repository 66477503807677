import { Alert, AlertIcon, Button, Flex, useToast } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers, getIn } from "formik";
import { useNavigate } from "react-router-dom";

import { API_ROOT, commonToastSetup } from "consts";
import { Routes } from "routes";
import { useStoreContext } from "contexts/StoreContext";
import { EStore, TStore } from "types/configFields";

import Layout from "./components/Layout";
import { initialStore } from "./consts";
import Fields from "./Fields";
import { useCreateStore } from "./hooks/useCreateStore";
import { payloadMapper } from "./mappers";
import { requiredMessage } from "./validationSchema/consts";
import { validationSchema } from "./validationSchema/validationSchema";

const StoreCreate = () => {
  const navigate = useNavigate();
  const { storeChains } = useStoreContext();
  const { fetchData, isLoading } = useCreateStore();
  const toast = useToast();

  const handleGoToError = () => {
    const errorMessage = document.querySelector('[class*="error-message"]');

    if (errorMessage) {
      errorMessage.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  };

  const onSubmit = async (
    values: TStore,
    formikHelpers: FormikHelpers<TStore>
  ) => {
    const storeId = values[EStore.STORE_ID];
    const storeChainId = getIn(values, EStore.CHAIN_ID);
    const currentChain =
      storeChains.find(chain => chain.chainId === storeChainId) || null;

    if (!currentChain) {
      return;
    }

    const { data, error } = await fetchData({
      url: `${API_ROOT}/stores/${storeId}`,
      body: payloadMapper(values, currentChain)
    });

    if (data) {
      toast({
        title: "Zapisano!",
        description: "Utworzono nowy sklep",
        status: "success",
        ...commonToastSetup
      });

      navigate(`${Routes.STORES}/`);
    }

    if (error) {
      if (!error.content) {
        toast({
          title: "Wystąpił błąd!",
          description: error.statusText,
          status: "error",
          ...commonToastSetup
        });
        return;
      } else {
        toast({
          title: "Wystąpił błąd!",
          render: () => (
            <Alert status="error" padding=".5rem 1rem">
              <Flex flexDir="column" alignItems="center">
                <Flex>
                  <AlertIcon />
                  Formularz zawiera błędy.
                </Flex>
                {error.content.detail && (
                  <Button
                    onClick={handleGoToError}
                    variant="ghost"
                    colorScheme="red"
                  >
                    Sprawdź
                  </Button>
                )}
                {error.content.errorMessage && (
                  <pre>
                    {JSON.stringify(error.content.errorMessage, null, 2)}
                  </pre>
                )}
              </Flex>
            </Alert>
          ),
          duration: 8000,
          isClosable: true
        });

        const errors: Record<string, any> = {};

        error.content.detail.forEach((error: any) => {
          const { loc, msg, type } = error;
          let currentLevel = errors;

          loc.forEach((key: any, index: number) => {
            if (index === loc.length - 1) {
              currentLevel[key] =
                type === "type_error.none.not_allowed" ||
                type === "value_error.missing"
                  ? requiredMessage
                  : msg;
            } else {
              if (!currentLevel[key]) {
                currentLevel[key] = {};
              }
              currentLevel = currentLevel[key];
            }
          });
        });

        const finalErrors = errors.body || {};
        const plainObject = JSON.parse(JSON.stringify(finalErrors));
        formikHelpers.setErrors(plainObject);
      }
    }
  };

  return (
    <Layout title="Tworzenie nowego sklepu">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialStore}
        validationSchema={validationSchema}
      >
        <Form>
          <Fields isLoading={isLoading} isCreate />
        </Form>
      </Formik>
    </Layout>
  );
};

export default StoreCreate;
