import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Field, getIn, useFormikContext } from "formik";

import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import { EComplaintDetails } from "../../../../methods/useSendComplaintsReview/types";

type TProps = {
  setProcess: () => void;
  isLoading: boolean;
};

const ComplaintRefoundModal = ({ setProcess, isLoading }: TProps) => {
  const modal = useDisclosure();
  const { submitForm, errors } = useFormikContext();

  const handleSave = () => {
    setProcess();
    submitForm();
  };

  return (
    <>
      <Button onClick={modal.onOpen} variant="solid" leftIcon={<CheckIcon />}>
        Zaakceptuj reklamcję ze zwrotem środków
      </Button>

      <Modal {...modal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text as="h2">Wykonanie zwrotu dla reklamacji.</Text>
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" gap="1rem">
              <FieldWrapper
                name={EComplaintDetails.REFUND_AMOUNT}
                label="Kwota zwrotu"
                errorMessage={getIn(errors, EComplaintDetails.REFUND_AMOUNT)}
              >
                <Field as={Input} name={EComplaintDetails.REFUND_AMOUNT} />
              </FieldWrapper>
            </Flex>
          </ModalBody>
          <ModalFooter display="flex" gap="1rem">
            <Button
              isLoading={isLoading}
              onClick={handleSave}
              colorScheme="green"
              variant="solid"
              minWidth="6rem"
            >
              Akceptuję
            </Button>

            <Button
              isDisabled={isLoading}
              onClick={modal.onClose}
              variant="ghost"
              minWidth="6rem"
            >
              Anuluj
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComplaintRefoundModal;
