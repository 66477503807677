import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { Form, useFormikContext, getIn } from "formik";
import { useNavigate } from "react-router-dom";

import FormField, { EFieldType } from "components/formField/FormField";
import { fieldLabels, sensitiveDataFields } from "pages/appConfigKey/constants";
import { getKeyFields } from "pages/appConfigKey/helpers";
import { availableAppConfigKeyTypes } from "pages/appConfigKeys/constants";

import { FormDataType } from "../../types";

import { CustomInput } from "./CustomInput";
import { EditableField } from "./EditableField";

type FieldsProps = {
  isLoading?: boolean;
  disabledFields?: string[];
};

export function Fields({ isLoading, disabledFields }: FieldsProps) {
  const navigate = useNavigate();
  const { values, errors } = useFormikContext<FormDataType>();

  const handleGoBack = () => navigate(-1);

  return (
    <Form>
      <Flex direction="column" gap="1rem" mb="1rem" maxW="40rem">
        <CustomInput
          name="id"
          label={fieldLabels.id}
          errorMessage={errors.id}
          isDisabled={disabledFields?.includes("id")}
        />
        <CustomInput
          name="name"
          label={fieldLabels.name}
          errorMessage={errors.name}
        />
        <FormField
          name="key.type"
          labelOverride="type"
          fieldType={EFieldType.SELECT}
          options={availableAppConfigKeyTypes.map(item => ({
            value: item,
            label: item
          }))}
          isDisabled={disabledFields?.includes("key.type")}
        />

        {getKeyFields(values.key.type).map(field => {
          if (sensitiveDataFields.includes(field)) {
            return (
              <EditableField
                key={field}
                name={`key.${field}`}
                label={fieldLabels[field]}
                errorMessage={getIn(errors, `key.${field}`)}
                placeholder={getIn(values, `key.${field}Mask`)}
              />
            );
          }

          return (
            <CustomInput
              key={field}
              name={`key.${field}`}
              label={fieldLabels[field]}
              errorMessage={getIn(errors, `key.${field}`)}
            />
          );
        })}

        <Flex gap="1rem" w="full" justifyContent="flex-end">
          <Button
            onClick={handleGoBack}
            leftIcon={<CloseIcon />}
            minW="6rem"
            mt="2rem"
            alignSelf="flex-end"
          >
            Anuluj
          </Button>
          <Button
            isLoading={isLoading}
            type="submit"
            leftIcon={<CheckIcon />}
            variant="solid"
            minW="6rem"
            mt="2rem"
            alignSelf="flex-end"
          >
            Zapisz
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
}
