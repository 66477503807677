import {
  EComplaintPreview,
  EComplaintProduct,
  EContent,
  EProductComplaintType,
  EProductInvalidPriceProblem,
  EProductProblemProblem,
  EProperty,
  TComplaintPreview,
  TProductComplaint
} from "pages/complaint/methods/useGetComplaint/types";

import {
  productAffectedLabels,
  productComplaintLabels,
  productPriceLabels
} from "./consts";

export const getProductComplaintText = (
  productComplaint: TProductComplaint
) => {
  const type = productComplaint[EProperty.TYPE];

  let message = "";

  switch (type) {
    case EProductComplaintType.INVALID_QUANTITY:
      message = `${
        productComplaintLabels[EProductComplaintType.INVALID_QUANTITY]
      } - Zabrano: ${productComplaint[EProperty.CORRECTED_QUANTITY]}`;
      break;
    case EProductComplaintType.OTHER:
      message = `${productComplaintLabels[EProductComplaintType.OTHER]}: ${
        productComplaint[EProperty.DESCRIPTION]
      }`;
      break;
    case EProductComplaintType.PRODUCT_PROBLEM: {
      message = `${productComplaintLabels[EProductComplaintType.PRODUCT_PROBLEM]} - `;
      const problem = productComplaint[EProperty.PROBLEM];
      const affectedQuantity = productComplaint[EProperty.AFFECTED_QUANTITY];

      switch (problem) {
        case EProductProblemProblem.DAMAGED:
          message += `${productAffectedLabels[EProductProblemProblem.DAMAGED]}`;
          break;
        case EProductProblemProblem.EXPIRED:
          message += `${productAffectedLabels[EProductProblemProblem.EXPIRED]}`;
          break;
        case EProductProblemProblem.INVALID_PREPARATION:
          message += `${productAffectedLabels[EProductProblemProblem.INVALID_PREPARATION]}`;
          break;
        default:
          break;
      }

      if (!!affectedQuantity) message += `, dotyczy: ${affectedQuantity}`;

      break;
    }

    case EProductComplaintType.INVALID_PRICE:
      {
        message = `${productComplaintLabels[EProductComplaintType.INVALID_PRICE]} - `;
        const problem = productComplaint[EProperty.PROBLEM];
        const affectedQuantity = productComplaint[EProperty.AFFECTED_QUANTITY];

        switch (problem) {
          case EProductInvalidPriceProblem.DIFF_THEN_PRICE_TAG:
            message += `${productPriceLabels[EProductInvalidPriceProblem.DIFF_THEN_PRICE_TAG]}`;
            break;
          case EProductInvalidPriceProblem.DIFF_THEN_PROMOTION:
            message += `${productPriceLabels[EProductInvalidPriceProblem.DIFF_THEN_PROMOTION]}`;
            break;
          case EProductInvalidPriceProblem.MULTI_PROMOTION_NOT_APPLIED:
            message += `${
              productPriceLabels[
                EProductInvalidPriceProblem.MULTI_PROMOTION_NOT_APPLIED
              ]
            }`;
            break;
          default:
            break;
        }

        if (!!affectedQuantity) message += `, dotyczy: ${affectedQuantity}`;
      }
      break;
    default:
      break;
  }

  return message;
};

export const messageMapper = (
  content: TComplaintPreview[EComplaintPreview.CONTENT]
) => {
  return {
    products:
      content[EContent.PRODUCTS]?.map(product => ({
        id: product[EProperty.PRODUCT_ID],
        text: getProductComplaintText(product[EComplaintProduct.COMPLAINT])
      })) || [],
    missingProducts:
      content[EContent.MISSING_PRODUCTS]?.[EProperty.DESCRIPTION] || "",
    receipt: content[EContent.RECEIPT]?.[EProperty.DESCRIPTION] || "",
    message: content[EContent.MESSAGE]?.[EProperty.BODY] || ""
  };
};
