import { ReactNode } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";

import SearchPanel from "./searchPanel/SearchPanel";

type TProps = {
  children: ReactNode;
  title: ReactNode;
};

const Layout = ({ children, title }: TProps) => {
  return (
    <>
      <CustomBreadcrumbs />
      <BorderedBox padding={{ base: "1rem", lg: "1.5rem" }}>
        <Heading textAlign="center" fontSize="1.5rem">
          {title}
        </Heading>
      </BorderedBox>
      <Box mt="1rem">
        <Flex
          maxHeight="74vh"
          overflow="auto"
          position="relative"
          id="store-wrapper"
        >
          <SearchPanel />

          {children}
        </Flex>
      </Box>
    </>
  );
};

export default Layout;
