import * as Yup from "yup";

import {
  ECLientPriority,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameterProperty,
  EParameters,
  EParameterSmsLanguage,
  PaymentMethodFields
} from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const cardIssuerSchema = Yup.object().shape({
  [EIssuerActionProperty.CARD_ISSUER_BIN]: Yup.string().when("isBin", {
    is: true,
    then(schema) {
      return schema.required(requiredMessage);
    }
  }),
  [EIssuerActionProperty.CARD_ISSUER_BRAND]: Yup.string().when("isBin", {
    is: false,
    then(schema) {
      return schema.required(requiredMessage);
    }
  }),
  [EIssuerActionProperty.ACTION]: Yup.object().shape({
    [EIssuerActionProperty.TYPE]: Yup.string().required(requiredMessage),
    [EIssuerActionProperty.PREAUTH_AMOUNT]: Yup.number().when(
      EIssuerActionProperty.TYPE,
      {
        is: (value: string) => value === EIssuerActionType.OVERRIDE_PREAUTH,
        then(schema) {
          return schema.required(requiredMessage);
        }
      }
    )
  })
});

const smsLanguagesSchema = Yup.object().shape({
  [EParameterSmsLanguage.PREFIX]: Yup.string().required(requiredMessage),
  [EParameterSmsLanguage.LANGUAGE]: Yup.string().required(requiredMessage)
});

const ordersRulesSchema = Yup.object().shape({
  [ECLientPriority.ORDERS_THRESHOLD]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
});

const periodRulesSchema = Yup.object().shape({
  [ECLientPriority.STARTS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.ENDS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
});

const retryPaymentMethodsSchema = Yup.object().shape({
  [PaymentMethodFields.NAME]: Yup.string().required(requiredMessage),
  [PaymentMethodFields.TYPE]: Yup.string().required(requiredMessage)
});

export const parametersSchema = Yup.object().shape({
  [EParameters.PURCHASE_CONFIRMATION_DOC]: Yup.object().shape({
    [EParameterProperty.TYPE]: Yup.string().required(requiredMessage),
    [EParameterProperty.DOMAIN]: Yup.string().required(requiredMessage)
  }),
  [EParameters.DEFAULT_LANGUAGE]: Yup.string().optional(),
  [EParameters.COUNTRY_CODE]: Yup.string().optional(),
  [EParameters.DEFAULT_PREAUTH_AMOUNT]: Yup.number()
    .typeError("Podana wartość musi być liczbą")
    .optional(),
  [EParameters.APP_PREAUTH_ENABLED]: Yup.boolean().optional(),
  [EParameters.CURRENCY]: Yup.string().optional(),
  [EParameters.SMS_LANGUAGES]: Yup.array()
    .required()
    .min(1, "Podaj minimum jeden język komunikacji")
    .of(smsLanguagesSchema),
  [EParameters.CARD_ISSUER_ACTIONS]: Yup.array().of(cardIssuerSchema),
  [EParameters.TIME_ZONE]: Yup.string().required(requiredMessage),
  [EParameters.CLIENT_PRIORITY]: Yup.object().shape({
    [ECLientPriority.ORDERS_RULES]: Yup.array().of(ordersRulesSchema),
    [ECLientPriority.DAILY_PERIOD_RULES]: Yup.array().of(periodRulesSchema),
    [ECLientPriority.PERIOD_RULES]: Yup.array().of(periodRulesSchema)
  }),
  [EParameters.RETRY_PAYMENT_METHODS]: Yup.array().of(retryPaymentMethodsSchema)
});
