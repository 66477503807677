import { ChakraStyledOptions } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { getValue } from "utils";

import { CustomSelect, TOption } from "./CustomSelect";

type TProps = {
  isLoading?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  name: string;
  options: TOption[];
  placeholder?: string;
} & ChakraStyledOptions;

export function FieldCustomSelect({
  isLoading,
  name,
  options,
  ...restProps
}: TProps) {
  const { values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<{
      [key: string]: string;
    }>();

  return (
    <CustomSelect
      value={getValue({ values, name })}
      onChange={value => {
        setFieldError(name, undefined);
        setFieldTouched(name, true);
        setFieldValue(name, value);
      }}
      {...{ name, isLoading, options }}
      {...restProps}
    />
  );
}
