import * as Yup from "yup";

import {
  ECLientPriority,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameterProperty,
  EParameterPropertySms,
  EParameters
} from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const testScheme = Yup.object().shape({
  [EIssuerActionProperty.CARD_ISSUER_BIN]: Yup.string().when("isBin", {
    is: true,
    then(schema) {
      return schema.required(requiredMessage);
    }
  }),
  [EIssuerActionProperty.CARD_ISSUER_BRAND]: Yup.string().when("isBin", {
    is: false,
    then(schema) {
      return schema.required(requiredMessage);
    }
  }),
  [EIssuerActionProperty.ACTION]: Yup.object().shape({
    [EIssuerActionProperty.TYPE]: Yup.string().required(requiredMessage),
    [EIssuerActionProperty.PREAUTH_AMOUNT]: Yup.number().when(
      EIssuerActionProperty.TYPE,
      {
        is: (value: string) => value === EIssuerActionType.OVERRIDE_PREAUTH,
        then(schema) {
          return schema.required(requiredMessage);
        }
      }
    )
  })
});

const boundariesError = "Ustaw wartość pomiędzy 0 a 10";

const ordersRulesSchema = Yup.object().shape({
  [ECLientPriority.ORDERS_THRESHOLD]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
    .min(0, boundariesError)
    .max(10, boundariesError)
});

const periodRulesSchema = Yup.object().shape({
  [ECLientPriority.STARTS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.ENDS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
    .min(0, boundariesError)
    .max(10, boundariesError)
});

export const parametersSchema = Yup.object()
  .optional()
  .shape({
    [EParameters.PURCHASE_CONFIRMATION_DOC]: Yup.object().shape({
      [EParameterProperty.TYPE]: Yup.string().optional(),
      [EParameterProperty.DOMAIN]: Yup.string().optional()
    }),

    [EParameters.DEFAULT_LANGUAGE]: Yup.string().optional(),
    [EParameters.COUNTRY_CODE]: Yup.string().optional(),
    [EParameters.DEFAULT_PREAUTH_AMOUNT]: Yup.number()
      .typeError(provideNumberMessage)
      .optional(),
    [EParameters.APP_PREAUTH_ENABLED]: Yup.boolean().optional(),
    [EParameters.CURRENCY]: Yup.string().optional(),
    [EParameters.SMS]: Yup.object().shape({
      [EParameterPropertySms.WELCOME_SMS]: Yup.string(),
      [EParameterPropertySms.RECEIPT_SMS]: Yup.string(),
      [EParameterPropertySms.EXIT_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_FAILED_SMS]: Yup.string(),
      [EParameterPropertySms.PBL_REQUESTED_SMS]: Yup.string(),
      [EParameterPropertySms.ACTIVATION_SMS]: Yup.string(),
      [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: Yup.string(),
      [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: Yup.string(),
      [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: Yup.string()
    }),
    [EParameters.CARD_ISSUER_ACTIONS]: Yup.array().nullable().of(testScheme),
    [EParameters.CLIENT_PRIORITY]: Yup.object().shape({
      [ECLientPriority.ORDERS_RULES]: Yup.array().of(ordersRulesSchema),
      [ECLientPriority.DAILY_PERIOD_RULES]: Yup.array().of(periodRulesSchema),
      [ECLientPriority.PERIOD_RULES]: Yup.array().of(periodRulesSchema),
      [ECLientPriority.STORE_RULE]: Yup.object().shape({
        [ECLientPriority.PRIORITY]: Yup.number()
          .typeError(provideNumberMessage)
          .required(requiredMessage)
          .min(0, boundariesError)
          .max(10, boundariesError)
      })
    })
  });
