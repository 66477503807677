import { EFieldType } from "./FormField";

const formatString = (value: string) => {
  const date = new Date(value);
  if (!isNaN(date.getTime())) {
    return date.toLocaleString();
  }
  return value;
};

export const getDisplayValue = (value: unknown, fieldType: EFieldType) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (typeof value === "boolean" || typeof value === "number") {
    return String(value);
  }

  if (value instanceof Date && !isNaN(value.valueOf())) {
    return value.toLocaleString();
  }

  // Strings coming from BE might be in date string format thus we want to check & format
  if (
    typeof value === "string" &&
    (fieldType === EFieldType.DATE || fieldType === EFieldType.DATETIME)
  ) {
    return formatString(value);
  }

  return String(value);
};
