import { useEffect } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { complaintMockPreview } from "mocks/complaintMockPreview";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";

import { tabs } from "./constants";
import { TComplaintPreview } from "./methods/useGetComplaint/types";
import useGetComplaint from "./methods/useGetComplaint/useGetComplaint";

export type TTabProps = {
  complaint: TComplaintPreview;
  isLoading: boolean;
};

export function ComplaintPreview() {
  const { [PathParameters.COMPLAINT_ID]: complaintId } = useParams();
  const getComplaint = useGetComplaint({ complaintId });

  useEffect(() => {
    getComplaint.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomBreadcrumbs />

      <BorderedBox padding="2rem" mt=".5rem">
        <Tabs variant="enclosed">
          <TabList borderColor="gray.400">
            {tabs.map(tab => (
              <Tab
                key={tab.label}
                _selected={{
                  color: "blue.dark",
                  border: "1px solid ",
                  bg: "white",
                  borderColor: "gray.400",
                  borderBottomColor: "white"
                }}
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map(tab => (
              <TabPanel key={tab.label}>
                <tab.component
                  complaint={complaintMockPreview}
                  isLoading={getComplaint.isLoading}
                  refetchComplaint={getComplaint.fetchData}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </BorderedBox>
    </>
  );
}
