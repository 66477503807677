import { EStoreAvailability } from "api/common/storeState";

import { EStoreState } from "types/configFields";

import { Columns, EPatternType, TFilters } from "./types";

export const getAvailableColumns = () => [
  { value: Columns.STORE_ID, label: "ID sklepu" },
  { value: Columns.NAME, label: "Nazwa sklepu" },
  { value: Columns.CHAIN_ID, label: "ID sieci" },
  { value: Columns.CHAIN_NAME, label: "Nazwa sieci" },
  { value: Columns.EXTERNAL_STORE_ID, label: "MPK" },
  {
    value: Columns.UPDATED_AT,
    label: "Ostatnia aktualizacja"
  },
  { value: Columns.STATE, label: "Stan" },
  {
    value: Columns.STORE_AVAILABILITY,
    label: "Dostępność"
  },
  { value: Columns.HEALTHCHECKS, label: "Healthchecki" },
  { value: Columns.AIFI_ID, label: "AiFi ID" },
  { value: Columns.SCOPE_NANOVO, label: "Scope Nanovo" }
];

export const defaultVisibleColumns: Columns[] = [
  Columns.EXTERNAL_STORE_ID,
  Columns.UPDATED_AT,
  Columns.STATE,
  Columns.STORE_AVAILABILITY,
  Columns.HEALTHCHECKS
];

export const availableSearchQueries: EPatternType[] = [
  EPatternType.EXTERNAL_STORE_ID,
  EPatternType.CHAIN_ID,
  EPatternType.AIFI_ID,
  EPatternType.SCOPE_NANOVO
];

export const STORE_STATE_OPTIONS = [
  { value: "ALL", label: "Dowolny" },
  { value: EStoreState.PRE_LIVE, label: "PRE LIVE" },
  { value: EStoreState.LIVE, label: "LIVE" },
  { value: EStoreState.ARCHIVED, label: "ARCHIVED" }
];

export const STORE_AVAILABILITY_OPTIONS = [
  { value: "ALL", label: "Dowolna" },
  { value: EStoreAvailability.OPEN, label: "Otwarty" },
  {
    value: EStoreAvailability.CLOSED_MAINTENANCE_MANUALLY,
    label: "Zamknięty ręcznie"
  },
  {
    value: EStoreAvailability.CLOSED_OUTSIDE_WORKING_HOURS,
    label: "Poza godzinami pracy"
  },
  {
    value: EStoreAvailability.CLOSED_MAINTENANCE_COMPONENT,
    label: "Awaria"
  },
  {
    value: EStoreAvailability.CLOSED_OTHER_REASON,
    label: "Inny powód"
  }
];

export const INITIAL_FILTERS: TFilters = {
  state: EStoreState.LIVE,
  storeAvailability: "ALL"
};
