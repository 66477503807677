import { Flex } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import { powerBouncerStatusOptions } from "pages/store/consts";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import { cardStyles } from "../../styles";

import MonitorFields from "./MonitorFields";

const PowerBouncer = () => {
  const keyPart = `${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreField
          fieldType={EFieldType.SELECT}
          name={`${keyPart}.${EComponentProperty.STATUS}`}
          options={powerBouncerStatusOptions}
        />

        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.TOKEN}`}
        />

        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.HELP_LINK}`}
        />

        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.HEARTBEAT_THRESHOLD}`}
        />

        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.IO_CONTROLLER}.${EComponentProperty.SERVICE_KEY}`}
        />

        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.AGE_ESTIMATION}.${EComponentProperty.MIN_ADULT_AGE}`}
        />
      </Flex>

      <Flex width="full" gap="1rem">
        <MonitorFields
          title="State monitor"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
        />

        <MonitorFields
          title="QRreader availbility monitor"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.QR_READER_AVAILABILITY}`}
        />
      </Flex>
    </>
  );
};

export default PowerBouncer;
