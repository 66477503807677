import { useFormikContext } from "formik";
import { getValue } from "utils";

import { CustomDatePicker, TDate } from "./CustomDatePicker";
import { parseDate } from "./helpers";

type TProps = {
  isDetailedTime?: boolean;
  isTime?: boolean;
  isDate?: boolean;
  name: string;
  minDate?: TDate;
  maxDate?: TDate;
};

export function FieldCustomDatePicker({
  isDetailedTime,
  isTime,
  isDate,
  name,
  minDate,
  maxDate
}: TProps) {
  const { values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<{
      [key: string]: string;
    }>();

  return (
    <CustomDatePicker
      value={parseDate(getValue({ values, name }))}
      onChange={date => {
        setFieldValue(name, date?.toISOString());
        setFieldTouched(name, true);
        setFieldError(name, "");
      }}
      {...{
        name,
        isDetailedTime,
        isTime,
        isDate,
        minDate,
        maxDate
      }}
    />
  );
}
