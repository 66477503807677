import { Text } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EComponent, EStore } from "types/configFields";

import { headers } from "../../labels";
import { headerStyles } from "../../styles";

import Adyen from "./Adyen";
import Aifi from "./Aifi";
import Infokiosk from "./Infokiosk";
import Pos from "./Pos";
import PowerBouncer from "./PowerBouncer";

const Components = () => {
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    mt: "0"
  } as const;

  return (
    <>
      <BorderedBox {...sectionStyles}>
        <Text id={`${EStore.COMPONENTS}.${EComponent.AIFI}`} {...headerStyles}>
          {headers[`${EStore.COMPONENTS}.${EComponent.AIFI}`]}
        </Text>
        <Aifi />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text id={`${EStore.COMPONENTS}.${EComponent.ADYEN}`} {...headerStyles}>
          {headers[`${EStore.COMPONENTS}.${EComponent.ADYEN}`]}
        </Text>
        <Adyen />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          id={`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]}
        </Text>
        <Infokiosk />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text id={`${EStore.COMPONENTS}.${EComponent.POS}`} {...headerStyles}>
          {headers[`${EStore.COMPONENTS}.${EComponent.POS}`]}
        </Text>
        <Pos />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          id={`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]}
        </Text>
        <PowerBouncer />
      </BorderedBox>
    </>
  );
};

export default Components;
