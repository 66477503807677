import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { PageNavigation } from "components/table/pageNavigation/PageNavigation";
import { PerPage } from "components/table/perPage/PerPage";

import { MAX_RESULTS, OFFSET } from "../constants";
import { EQueryParams, TQueryParams } from "../methods/useGetComplaints/types";

type TProps = {
  children: React.ReactElement;
};

export function PaginationSection({ children }: TProps) {
  const { values, setFieldValue, submitForm } =
    useFormikContext<TQueryParams>();

  const offset = values[EQueryParams.OFFSET] || OFFSET;
  const maxResults = values[EQueryParams.MAX_RESULTS] || MAX_RESULTS;
  const nextPageOffset = offset + maxResults;

  useEffect(() => {
    console.log({ values });
  }, [values]);

  const onBack = () => {
    setFieldValue(EQueryParams.OFFSET, offset - maxResults);
    submitForm();
  };
  const onForward = () => {
    setFieldValue(EQueryParams.OFFSET, nextPageOffset);
    submitForm();
  };

  const firstItem = offset;
  const lastItem = offset + maxResults;

  const isForwardDisabled = !nextPageOffset;
  const isBackDisabled = firstItem === 1;

  const props = {
    firstItem,
    lastItem,
    onBack,
    onForward,
    isForwardDisabled,
    isBackDisabled
  };

  return (
    <>
      <Flex justifyContent="space-between" marginBlock="3rem .5rem">
        <PerPage name={EQueryParams.MAX_RESULTS} />
        <PageNavigation {...props} />
      </Flex>
      {children}
      <Flex justifyContent="space-between" marginBlock=".5rem">
        <PerPage name={EQueryParams.MAX_RESULTS} />
        <PageNavigation {...props} />
      </Flex>
    </>
  );
}
