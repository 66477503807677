import { useMemo, useState } from "react";
import { FailedFetchingAlert } from "components";
import find from "lodash/find";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { TableBottomSection } from "components/tableBottomSection/TableBottomSection";
import { TableContextProvider } from "contexts/TableContext";
import { useSearch } from "hooks/useSearch";
import { useStoreChainsData } from "pages/storeChains/useStoreChainsData";

import { StoresTable } from "./components/StoresTable";
import { TopSection } from "./components/TopSection";
import {
  availableSearchQueries,
  defaultVisibleColumns,
  INITIAL_FILTERS
} from "./consts";
import { useStoresData } from "./hooks/useStoresData";
import { StoreWithChainName, TFilters } from "./types";
import { getFilteredStores } from "./utils";

export const Stores = () => {
  const { searchValue, changeSearchValue, clearSearch } = useSearch();
  const [searchPatternType, setSearchPatternType] = useState(
    availableSearchQueries[0]
  );
  const [filters, setFilters] = useState<TFilters>(INITIAL_FILTERS);
  const { storeChains } = useStoreChainsData();
  const { stores, error, isLoading } = useStoresData();

  const storesWithChainNames = useMemo(
    () =>
      stores.map(store => {
        const chainForCurrentStore = find(
          storeChains,
          chain => chain.chainId === store.chainId
        );

        if (chainForCurrentStore) {
          return {
            ...store,
            chainName: chainForCurrentStore.name
          };
        }

        return store;
      }),
    [storeChains, stores]
  ) as StoreWithChainName[];

  const filteredStores = getFilteredStores(
    storesWithChainNames,
    searchValue,
    searchPatternType,
    filters
  ) as StoreWithChainName[];

  return (
    <TableContextProvider defaultVisibleColumns={defaultVisibleColumns}>
      <TopSection
        searchValue={searchValue}
        changeSearchValue={changeSearchValue}
        clearSearch={clearSearch}
        searchPatternType={searchPatternType}
        setSearchPatternType={setSearchPatternType}
        filters={filters}
        setFilters={setFilters}
      />

      {error ? (
        <FailedFetchingAlert mt="30px" />
      ) : (
        <BorderedBox>
          {!!stores.length && <StoresTable stores={filteredStores} />}

          <TableBottomSection
            isLoading={isLoading}
            noData={!stores.length}
            entityName="sklepów"
          />
        </BorderedBox>
      )}
    </TableContextProvider>
  );
};
