// https://api.nanostore.sa-rnd.devguru.co/themis/versions/1.1/#operation/post-orders-complaints-review

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { TComplaintApproved, TComplaintRejected } from "./types";

const getOrdersComplaintReviewUrl = ({
  orderId,
  complaintId
}: {
  orderId: string;
  complaintId: string;
}) => `${API_ROOT}/orders/${orderId}/complaints/${complaintId}/review`;

type TProps = {
  orderId: string;
  complaintId: string;
};

const useSendComplaintsReview = ({ orderId, complaintId }: TProps) => {
  const url = getOrdersComplaintReviewUrl({
    orderId,
    complaintId
  });

  const fetch = useFetchRefactored<
    TComplaintRejected | TComplaintApproved,
    any
  >({
    url,
    method: "POST"
  });

  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready
  return {
    data: {},
    isLoading: false,
    fetchData: () =>
      new Promise<{ data: {}; error: any }>(resolve =>
        resolve({
          data: {},
          error: null
        })
      )
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useSendComplaintsReview;
