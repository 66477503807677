import { Flex } from "@chakra-ui/react";

import { Property } from "components/property";
import { dateFormat } from "utils/dateFormat";

import { TTabProps } from "../ComplaintPreview";
import UserMessage from "../components/UserMessage/UserMessage";
import { labels } from "../constants";
import { EComplaintPreview } from "../methods/useGetComplaint/types";

export function Complaint({ complaint, isLoading }: TTabProps) {
  return (
    <Flex direction="column" gap="1rem">
      <Property label={labels[EComplaintPreview.ID]} isLoading={isLoading}>
        {complaint[EComplaintPreview.ID]}
      </Property>
      <Property
        label={labels[EComplaintPreview.CREATED_AT]}
        isLoading={isLoading}
      >
        {dateFormat(complaint[EComplaintPreview.CREATED_AT])}
      </Property>
      <Property
        label={labels[EComplaintPreview.UPDATED_AT]}
        isLoading={isLoading}
      >
        {dateFormat(complaint[EComplaintPreview.UPDATED_AT])}
      </Property>
      <Property label={labels[EComplaintPreview.STATUS]} isLoading={isLoading}>
        {complaint[EComplaintPreview.STATUS]}
      </Property>
      <Property label={labels[EComplaintPreview.TRACK]} isLoading={isLoading}>
        {complaint[EComplaintPreview.TRACK]}
      </Property>
      <Property label={labels[EComplaintPreview.SOURCE]} isLoading={isLoading}>
        {complaint[EComplaintPreview.SOURCE]}
      </Property>

      <UserMessage content={complaint[EComplaintPreview.CONTENT]} />
    </Flex>
  );
}
