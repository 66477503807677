export type TDataFormat = any[];

const flattenObject = ({
  obj,
  parentKey = "",
  separator = "."
}: {
  obj: TDataFormat;
  parentKey?: string;
  separator?: string;
}) => {
  if (!obj) return {};

  const flattened: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
      Object.assign(
        flattened,
        flattenObject({
          obj: value,
          parentKey: newKey,
          separator
        })
      );
    } else {
      const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
      flattened[newKey] = value;
    }
  }

  return flattened;
};

const flattenArray = (arr: TDataFormat) =>
  arr.map(obj => flattenObject({ obj }));

const getNormalizedData = (data: TDataFormat) => {
  const normalizedData = [];

  const columnNames = Object.keys(data[0]);

  normalizedData.push(columnNames);
  data.forEach(row => {
    const values = Object.values(row);

    normalizedData.push(values);
  });

  return normalizedData;
};

export const createCsvFile = (data: TDataFormat) => {
  const flatData = flattenArray(data);
  const normalizedData = getNormalizedData(flatData);
  let csvContent = "";

  normalizedData.forEach(row => {
    csvContent += row.join(",") + "\n";
  });

  const blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8,"
  });

  return blob;
};
