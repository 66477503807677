import { Button, Flex } from "@chakra-ui/react";
import { Field, useFormikContext } from "formik";

import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";
import { SearchInput } from "components/table/searchInput/SearchInput";

import { searchOptions } from "../constants";
import {
  EHelperQueryParams,
  TQueryParams
} from "../methods/useGetComplaints/types";

export function Search() {
  const { setFieldValue } = useFormikContext<TQueryParams>();

  const handleSearch = () => {
    // TODO: https://netguru.atlassian.net/browse/ZN-5687
  };

  const handleSearchValueClear = () => {
    setFieldValue(EHelperQueryParams.SEARCH_VALUE, "");
  };

  return (
    <Flex gap=".5rem" w="100%" maxW="40%">
      <FieldCustomSelect
        name={EHelperQueryParams.SEARCH_KEY}
        options={searchOptions}
      />

      <Field
        as={SearchInput}
        name={EHelperQueryParams.SEARCH_VALUE}
        onClear={handleSearchValueClear}
        placeholder="szukaj reklamacji"
      />

      <Button onClick={handleSearch} p="0 15px" variant="solid">
        Szukaj
      </Button>
    </Flex>
  );
}
