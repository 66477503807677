import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { getBreadCrumbs } from "./helpers";
import { LabelsType } from "./types";

type CustomBreadcrumbsProps = {
  uniqueLabels?: LabelsType;
};

export function CustomBreadcrumbs({
  uniqueLabels
}: CustomBreadcrumbsProps): JSX.Element {
  const location = useLocation();
  const fragments = location.pathname.split("/").filter(Boolean);
  const breadCrumbs = getBreadCrumbs(fragments, uniqueLabels);

  return (
    <Breadcrumb
      spacing="0.125rem"
      color="gray.600"
      fontWeight="medium"
      fontSize="sm"
      ml="1rem"
      separator={<ChevronRightIcon />}
    >
      {breadCrumbs.map(({ isCurrentPage, label, path }) => (
        <BreadcrumbItem key={path} isCurrentPage={isCurrentPage}>
          <BreadcrumbLink
            {...(isCurrentPage
              ? { color: "blue.dark" }
              : { as: Link, to: `/${path}` })}
          >
            {label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default CustomBreadcrumbs;
