import { useState } from "react";
import { EditIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { useField } from "formik";

import { CustomInput, CustomInputProps } from "./CustomInput";

type EditableFieldProps = CustomInputProps;

export const EditableField = (props: EditableFieldProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [, { touched: isTouched }] = useField(props.name);

  const handleEdit = () => setIsEditable(true);

  if (isEditable || !props.placeholder)
    return <CustomInput {...props} isMasked />;

  return (
    <FormControl isInvalid={isTouched && !!props.errorMessage}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <InputGroup>
        <Input readOnly value={props.placeholder} />
        <InputRightElement>
          <IconButton
            onClick={handleEdit}
            icon={<EditIcon />}
            variant="solid"
            h="2rem"
            w="2rem"
            aria-label="edycja"
          />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};
