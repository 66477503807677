import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text
} from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { stickyHeadingStyles } from "pages/store/styles";
import {
  EStore,
  EStoreService,
  EStoreServiceProperty
} from "types/configFields";

import StoreChainField from "../components/StoreChainField";
import { sectionHeaderStyles } from "../consts";

const Services = () => {
  return (
    <AccordionItem id="services">
      <AccordionButton
        mt="0.5rem"
        pb="1rem"
        {...stickyHeadingStyles}
        borderRadius=".5rem"
      >
        Dane konfiguracyjne sieci zewnętrznych
        <AccordionIcon position="relative" />
      </AccordionButton>
      <AccordionPanel pt="0">
        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.SERVICES}.${EStoreService.SHORTENER}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja shortenera URLi
          </Text>
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.SHORTENER}.${EStoreServiceProperty.SERVICE_KEY}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.SHORTENER}.${EStoreServiceProperty.DOMAIN}`}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.SERVICES}.${EStoreService.SYNERISE}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja CRMa - Synerise
          </Text>
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.SYNERISE}.${EStoreServiceProperty.SERVICE_KEY}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.SYNERISE}.${EStoreServiceProperty.SUPER_LOGIN_SERVICE_KEY}`}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.SERVICES}.${EStoreService.ERP}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja systemu ERP
          </Text>
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.IS_ENABLED}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.SERVICE_KEY}`}
          />
        </BorderedBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Services;
