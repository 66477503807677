import * as Yup from "yup";

import { EComponent, EComponentProperty, EMonitor } from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const boundaries100Error = "Ustaw wartość pomiędzy 0 a 100";

const monitorSchema = Yup.object()
  .nullable()
  .shape({
    [EMonitor.IS_ENABLED]: Yup.boolean().optional(),
    [EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS]: Yup.boolean(),
    [EMonitor.ACTIONS]: Yup.object()
      .nullable()
      .shape({
        [EMonitor.STORE_STATE_MODIFIER]: Yup.object().shape({
          [EMonitor.IS_ACTIVE]: Yup.boolean(),
          [EMonitor.CLOSE_THRESHOLD]: Yup.number()
            .typeError(provideNumberMessage)
            .min(0, boundaries100Error)
            .max(100, boundaries100Error),
          [EMonitor.CLOSE_PERIOD]: Yup.number().typeError(provideNumberMessage),
          [EMonitor.OPEN_THRESHOLD]: Yup.number()
            .typeError(provideNumberMessage)
            .min(0, boundaries100Error)
            .max(100, boundaries100Error),
          [EMonitor.OPEN_PERIOD]: Yup.number().typeError(provideNumberMessage)
        })
      })
  });

const aifiSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.STATE]: monitorSchema
      })
  });

const adienSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.MERCHANT_ACCOUNT]:
      Yup.string().required(requiredMessage),
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.DEFAULT_LOCALE]: Yup.string().required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema
      })
  });

const posSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.TYPE]: Yup.string().required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema,
        [EComponentProperty.PAPER_STATE]: monitorSchema
      })
  });

const infokioskSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.HEARTBEAT_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .required()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema
      })
  });

const powerBouncerSchema = Yup.object()
  .optional()
  .shape({
    [EComponentProperty.TOKEN]: Yup.string(),
    [EComponentProperty.HELP_LINK]: Yup.string(),
    [EComponentProperty.HEARTBEAT_THRESHOLD]: Yup.number(),
    [EComponentProperty.IO_CONTROLLER]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.SERVICE_KEY]: Yup.string()
      }),
    [EComponentProperty.AGE_ESTIMATION]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.MIN_ADULT_AGE]: Yup.number()
      }),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema,
        [EComponentProperty.QR_READER_AVAILABILITY]: monitorSchema
      })
  });

export const componentsSchema = Yup.object()
  .required()
  .shape({
    [EComponent.AIFI]: aifiSchema,
    [EComponent.ADYEN]: adienSchema,
    [EComponent.POS]: posSchema,
    [EComponent.INFOKIOSK]: infokioskSchema,
    [EComponent.POWER_BOUNCER]: powerBouncerSchema
  });
