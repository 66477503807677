// https://api.nanostore.sa-rnd.devguru.co/themis/public/versions/1.1/#operation/get-complaints-stats

import { complaintsStatsMock } from "mocks/complaintStatsMock";

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { EComplaintPreview, TComplaintPreview } from "../useGetComplaint/types";

import { TComplaintsStats } from "./types";

const getComplaintsStatsUrl = () => `${API_ROOT}/complaints/stats`;

const useGetComplaintsStats = (
  complaintId: TComplaintPreview[EComplaintPreview.ID]
) => {
  const fetch = useFetchRefactored<any, TComplaintsStats>({
    url: getComplaintsStatsUrl(),
    queryParams: { complaintId }
  });

  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready

  return {
    data: complaintsStatsMock,
    isLoading: false,
    fetchData: () => {}
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useGetComplaintsStats;
