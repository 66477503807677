import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text } from "@chakra-ui/react";

type TProps = {
  isForwardDisabled: boolean;
  isBackDisabled: boolean;
  firstItem: number;
  lastItem?: number;
  onBack: () => void;
  onForward: () => void;
};

export function PageNavigation({
  isForwardDisabled,
  isBackDisabled,
  firstItem,
  lastItem,
  onBack,
  onForward
}: TProps) {
  const buttonStyles = {
    height: "2.25rem",
    width: "2.25rem",
    variant: "ghost"
  };

  return (
    <Flex alignItems="center" gap="1rem">
      <IconButton
        onClick={onBack}
        isDisabled={isBackDisabled}
        icon={<ArrowBackIcon />}
        aria-label="poprzednia strona"
        {...buttonStyles}
      />
      <Text fontSize=".8rem">
        {firstItem} - {lastItem}
      </Text>
      <IconButton
        onClick={onForward}
        isDisabled={isForwardDisabled}
        icon={<ArrowForwardIcon />}
        aria-label="następna strona"
        {...buttonStyles}
      />
    </Flex>
  );
}
