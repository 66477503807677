import { Flex, Text } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { cardStyles, headerStyles } from "pages/store/styles";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import MonitorFields from "./MonitorFields";
import StoreChainField from "./StoreChainField";

const PowerBouncer = () => {
  const keyPart = `${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreChainField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.HELP_LINK}`}
        />

        <StoreChainField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.HEARTBEAT_THRESHOLD}`}
        />

        <BorderedBox>
          <Text {...headerStyles} fontSize="14px">
            Konfiguracja kontrolera IO odpowiedzialnego za obsługę elektrozamka
            i czujników drzwi lodówki
          </Text>
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.IO_CONTROLLER}.${EComponentProperty.SERVICE_KEY}`}
          />
        </BorderedBox>

        <BorderedBox>
          <Text {...headerStyles} fontSize="14px">
            Konfiguracja estymacji wieku w oparciu o skan twarzy
          </Text>
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.AGE_ESTIMATION}.${EComponentProperty.MIN_ADULT_AGE}`}
          />
        </BorderedBox>
      </Flex>

      <Flex width="full" gap="1rem">
        <MonitorFields
          title="Konfiguracja monitora dostępności power-bouncer"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
        />

        <MonitorFields
          title="Konfiguracja monitora dostępności skanera QR dla aplikacji power-bouncer"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.QR_READER_AVAILABILITY}`}
        />
      </Flex>
    </>
  );
};

export default PowerBouncer;
