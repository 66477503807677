import { Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { CirclePlusIcon } from "lucide-react";
import { getValue } from "utils";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import StoreChainValue from "pages/store/components/StoreChainValue";
import StoreField from "pages/store/components/StoreField";
import { emptyDateValues } from "pages/store/consts";
import { EDay, ESchedule, EStore, TStore } from "types/configFields";

import { headers } from "../../labels";
import { noDataStyles, tilesContainerStyles } from "../../styles";

import AdjustDay from "./AdjustDayTile";
import CloseDayTile from "./CloseDayTile";
import OpeningHoursTile from "./OpeningHoursTile";

const OpeningSchedule = () => {
  const { values, setFieldValue } = useFormikContext<TStore>();
  const { isReadOnly } = useFieldGenerationContext();
  const exceptionKey = `${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`;
  const adjustedKey = `${exceptionKey}.${ESchedule.ADJUSTED_DAYS}`;
  const closedKey = `${exceptionKey}.${ESchedule.CLOSED_DAYS}`;

  const handleAddAdjustHours = () => {
    setFieldValue(adjustedKey, [
      ...getValue({
        values,
        name: adjustedKey
      }),
      emptyDateValues[ESchedule.ADJUSTED_DAYS]
    ]);
  };
  const handleAddCloseDay = () => {
    setFieldValue(closedKey, [
      ...getValue({
        values,
        name: closedKey
      }),
      emptyDateValues[ESchedule.CLOSED_DAYS]
    ]);
  };
  const handleRemoveAdjustHours = (index: number) => {
    const currentValues: (typeof emptyDateValues)[ESchedule.ADJUSTED_DAYS][] =
      getValue({
        values,
        name: adjustedKey
      });
    const filteredValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(adjustedKey, filteredValues);
  };
  const handleRemoveCloseDay = (index: number) => {
    const currentValues: (typeof emptyDateValues)[ESchedule.CLOSED_DAYS][] =
      getValue({
        values,
        name: closedKey
      });
    const filteredValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(closedKey, filteredValues);
  };

  const closeDays =
    values?.[EStore.OPENING_SCHEDULE]?.[ESchedule.EXCEPTIONS]?.[
      ESchedule.CLOSED_DAYS
    ] || [];
  const adjustedDays =
    values?.[EStore.OPENING_SCHEDULE]?.[ESchedule.EXCEPTIONS]?.[
      ESchedule.ADJUSTED_DAYS
    ] || [];

  return (
    <BorderedBox mt="0">
      <StoreField
        name={`${EStore.OPENING_SCHEDULE}.${ESchedule.IS_ENABLED}`}
        fieldType={EFieldType.CHECKBOX}
      />

      <Text
        id={`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`}
        fontSize=".8rem"
        fontWeight="bold"
        m="0"
        mt="2rem"
      >
        {headers[`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`]}
      </Text>
      <SimpleGrid gap="2rem" columns={{ sm: 1, md: 2, lg: 3 }}>
        {Object.values(EDay).map(day => (
          <OpeningHoursTile key={day} day={day} />
        ))}
      </SimpleGrid>

      <Text
        id={`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`}
        fontSize=".8rem"
        fontWeight="bold"
        m="0"
        mt="2rem"
      >
        {headers[`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`]}
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="2rem"
        minH="2.5rem"
      >
        <Text fontSize=".8rem" fontWeight="bold" m="0">
          Lista dni, w które sklep jest otwarty w innych godzinach niż wynika z
          bazowego harmonogramu
        </Text>

        {!isReadOnly && (
          <Button
            onClick={handleAddAdjustHours}
            variant="ghost"
            leftIcon={<CirclePlusIcon size="1rem" />}
            m="0"
          >
            Dodaj inne godziny
          </Button>
        )}
      </Flex>

      <Flex {...tilesContainerStyles} mb="0.5rem">
        {adjustedDays.map((_exception, index) => (
          <AdjustDay
            index={index}
            key={index}
            onRemove={() => handleRemoveAdjustHours(index)}
          />
        ))}
        {!adjustedDays.length && (
          <Text {...noDataStyles}>Brak innych godzin otwarcia</Text>
        )}
      </Flex>
      <StoreChainValue
        name={`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}.${ESchedule.ADJUSTED_DAYS}`}
      />

      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="2rem"
        minH="2.5rem"
      >
        <Text fontSize=".8rem" fontWeight="bold" m="0">
          Lista dni, w które sklepy jest zamknięty
        </Text>

        {!isReadOnly && (
          <Button
            onClick={handleAddCloseDay}
            variant="ghost"
            leftIcon={<CirclePlusIcon size="1rem" />}
            m="0"
          >
            Dodaj dzień zamknięcia
          </Button>
        )}
      </Flex>

      <Flex {...tilesContainerStyles} mb=".5rem">
        {closeDays.map((_exception, index) => (
          <CloseDayTile
            index={index}
            key={index}
            onRemove={() => handleRemoveCloseDay(index)}
          />
        ))}
        {!closeDays.length && (
          <Text {...noDataStyles}>Brak dni zamknięcia</Text>
        )}
      </Flex>
      <StoreChainValue
        name={`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}.${ESchedule.CLOSED_DAYS}`}
      />
    </BorderedBox>
  );
};

export default OpeningSchedule;
