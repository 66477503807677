import { StoreChainConfig } from "api/common";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isObject from "lodash/isObject";
import isPlainObject from "lodash/isPlainObject";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import mergeWith from "lodash/mergeWith";
import omit from "lodash/omit";

import { StoreData } from "pages/common/storeState/types";
import {
  ECLientPriority,
  EComponentType,
  EDay,
  EProperty,
  ESchedule,
  EStore,
  PeriodRule,
  TAdjustedDay,
  TStore,
  TStoreBaseSchedule,
  TStoreOpeningSchedule
} from "types/configFields";
import {
  dateFormatter,
  formatFromUTCTimeToLocal,
  timeFormatter
} from "utils/dateTime";

import { emptyActivation, emptyBase, emptyClientPriority } from "./consts";

const replaceWithInitial = (
  initial: Record<string, any>,
  configPart?: Record<string, any>
) => (!configPart ? initial : configPart);

const openingScheduleMapper = (
  openingSchedule: TStoreOpeningSchedule | null | undefined,
  shouldFormatToLocal?: boolean
) => {
  if (!openingSchedule) {
    return null;
  }

  const { base, exceptions } = openingSchedule;

  let mappedBase: TStoreBaseSchedule | undefined;
  let mappedClosedDays: string[];
  let mappedAdjustedDays: TAdjustedDay[];

  const mergedWithEmptySchedule = Object.keys(emptyBase).reduce((acc, day) => {
    const dayKey = day as EDay;
    acc[dayKey] = {
      ...emptyBase[dayKey],
      ...(base ? base[dayKey] : {})
    };
    return acc;
  }, {} as TStoreBaseSchedule);

  if (!base) {
    mappedBase = undefined;
  } else {
    mappedBase = Object.fromEntries(
      Object.entries(mergedWithEmptySchedule).map(el => {
        const [dayName, value] = el;

        if (!value) {
          return [dayName, { opensAt: "", closesAt: "" }];
        }

        const formattedValue = Object.fromEntries(
          Object.entries(value).map(el => {
            const [intervalKey, time] = el;

            if (!time) {
              return [intervalKey, ""];
            }

            const formattedTime = shouldFormatToLocal
              ? formatFromUTCTimeToLocal(time)
              : timeFormatter(time, true);
            return [intervalKey, formattedTime];
          })
        );

        return [dayName, formattedValue];
      })
    ) as TStoreBaseSchedule;
  }

  if (exceptions && exceptions.closedDays) {
    mappedClosedDays = exceptions.closedDays.map(day => dateFormatter(day));
  } else {
    mappedClosedDays = [];
  }

  if (exceptions && exceptions.adjustedDays) {
    mappedAdjustedDays = exceptions.adjustedDays.map(day => {
      const {
        [EProperty.DAY]: dayName,
        [EProperty.OPENS_AT]: opensAt,
        [EProperty.CLOSES_AT]: closesAt
      } = day;

      return {
        [EProperty.DAY]: dateFormatter(dayName),
        [EProperty.OPENS_AT]: shouldFormatToLocal
          ? formatFromUTCTimeToLocal(opensAt)
          : timeFormatter(opensAt, true),
        [EProperty.CLOSES_AT]: shouldFormatToLocal
          ? formatFromUTCTimeToLocal(closesAt)
          : timeFormatter(closesAt, true)
      };
    });
  } else {
    mappedAdjustedDays = [];
  }

  return {
    [ESchedule.BASE]: mappedBase,
    [ESchedule.EXCEPTIONS]: {
      [ESchedule.CLOSED_DAYS]: mappedClosedDays,
      [ESchedule.ADJUSTED_DAYS]: mappedAdjustedDays
    },
    [ESchedule.IS_ENABLED]: !!openingSchedule.isEnabled
  };
};

const dailyPeriodRulesMapper = (dailyPeriodRules: PeriodRule[]) =>
  dailyPeriodRules.map(rule => ({
    [ECLientPriority.STARTS_AT]: timeFormatter(
      rule[ECLientPriority.STARTS_AT],
      true
    ),
    [ECLientPriority.ENDS_AT]: timeFormatter(
      rule[ECLientPriority.ENDS_AT],
      true
    ),
    [ECLientPriority.PRIORITY]: rule[ECLientPriority.PRIORITY]
  }));

const getPosValues = (type: EComponentType, posValues: any) => {
  switch (type) {
    case EComponentType.ERP:
      return omit(posValues, ["ipos", "posService"]);
    case EComponentType.IPOS:
      return omit(posValues, ["posService"]);
    case EComponentType.POS_SERVICE:
      return omit(posValues, ["ipos"]);
    default:
      return omit(posValues, ["ipos", "posService"]);
  }
};

const replaceUndefinedWithChainValue = (objValue: any, srcValue: any): any => {
  if (isPlainObject(objValue) && isPlainObject(srcValue)) {
    return mergeWith({}, objValue, srcValue, replaceUndefinedWithChainValue);
  }
  if (
    isUndefined(objValue) ||
    isNull(objValue) ||
    (isString(objValue) && objValue.trim() === "") ||
    (isArray(objValue) && objValue.length === 0) ||
    (isObject(objValue) && isEmpty(objValue))
  ) {
    return srcValue;
  }
  return objValue;
};

export const payloadMapper = (
  payload: TStore,
  chainConfig: StoreChainConfig
): TStore => {
  const merged = mergeWith(
    {},
    payload,
    omit(chainConfig, ["chainId", "createdAt", "updatedAt", "name"]),
    replaceUndefinedWithChainValue
  );

  const { parameters, components, openingSchedule, ...restPayload } = merged;

  const mappedSchedule = openingScheduleMapper(openingSchedule);
  const mappedDailyPeriodRules = dailyPeriodRulesMapper(
    parameters.clientPriority.dailyPeriodRules
  );

  return {
    ...restPayload,
    [EStore.PARAMETERS]: {
      ...parameters,
      clientPriority: {
        ...parameters.clientPriority,
        dailyPeriodRules: mappedDailyPeriodRules
      }
    },
    [EStore.COMPONENTS]: {
      ...components,
      pos: components.pos.type
        ? getPosValues(components.pos.type, components.pos)
        : components.pos
    },
    [EStore.OPENING_SCHEDULE]: mappedSchedule
  };
};

export const responseMapper = (response: TStore | StoreData | null) => {
  if (!response) {
    return {};
  }

  const { openingSchedule, parameters, ...restResponse } = response;

  const mappedSchedule = openingScheduleMapper(openingSchedule, true);

  return {
    ...restResponse,
    parameters: {
      ...parameters,
      clientPriority: replaceWithInitial(
        emptyClientPriority,
        parameters?.clientPriority
      ),
      activation: replaceWithInitial(emptyActivation, parameters?.activation)
    },
    openingSchedule: mappedSchedule
  };
};
