import { useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { isProduction } from "utils";

import FieldGenerationContextProvider from "contexts/FieldGenerationContext";

import FieldsFooter from "./components/FieldsFooter";
import { headers, label } from "./labels";
import Components from "./sections/components/Components";
import GeneralInfo from "./sections/generalInfo/GeneralInfo";
import OpeningSchedule from "./sections/openingSchedule/OpeningSchedule";
import Parameters from "./sections/parameters/Parameters";
import Services from "./sections/services/Services";
import { stickyHeadingStyles, sectionStyles } from "./styles";

type TProps = {
  isLoading: boolean;
  isEdit?: boolean;
  isCreate?: boolean;
  isReadOnly?: boolean;
};

const Fields = ({ isLoading, isEdit, isCreate, isReadOnly }: TProps) => {
  const { errors } = useFormikContext();

  // Temp console to detect legacy validaiton errors sometimes
  // preventing submission on different envs. To remove when it's resolved.
  useEffect(() => {
    if (!isProduction()) {
      console.log(errors);
    }
  }, [errors]);

  return (
    <FieldGenerationContextProvider
      isReadOnly={isReadOnly}
      labels={label}
      isCreate={isCreate}
      isEdit={isEdit}
    >
      <Flex direction="column" gap="1rem">
        <Flex {...sectionStyles}>
          <Text id="general" {...stickyHeadingStyles}>
            {headers.general}
          </Text>
          <GeneralInfo isEdit={isEdit} isCreate={isCreate} />
        </Flex>

        <Flex {...sectionStyles}>
          <Text id="parameters" {...stickyHeadingStyles}>
            {headers.parameters}
          </Text>
          <Parameters />
        </Flex>

        <Flex {...sectionStyles}>
          <Text id="services" {...stickyHeadingStyles}>
            {headers.services}
          </Text>
          <Services />
        </Flex>

        <Flex {...sectionStyles}>
          <Text id="components" {...stickyHeadingStyles}>
            {headers.components}
          </Text>
          <Components />
        </Flex>

        <Flex {...sectionStyles}>
          <Text id="openingSchedule" {...stickyHeadingStyles}>
            {headers.openingSchedule}
          </Text>
          <OpeningSchedule />
        </Flex>

        {!isReadOnly && <FieldsFooter isLoading={isLoading} />}
      </Flex>
    </FieldGenerationContextProvider>
  );
};

export default Fields;
