import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import { CirclePlusIcon } from "lucide-react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import StoreField from "pages/store/components/StoreField";
import {
  emptyBinAction,
  emptyBrandAction,
  purchaseConfirmationDocTypesOptions,
  smsOptions
} from "pages/store/consts";
import {
  ECLientPriority,
  EParameterProperty,
  EParameterPropertySms,
  EParameters,
  EStore,
  TCardIssuerActionBin,
  TCardIssuerActionScheme,
  TStore
} from "types/configFields";

import { headers } from "../../labels";
import { cardStyles, noDataStyles, tilesContainerStyles } from "../../styles";

import Action from "./Action";
import DailyPeriodRules from "./DailyPeriodRules";
import OrdersRules from "./OrdersRules";
import PeriodRules from "./PeriodRules";

const Parameters = () => {
  const { values, setFieldValue } = useFormikContext<TStore>();
  const actions =
    values?.[EStore.PARAMETERS]?.[EParameters.CARD_ISSUER_ACTIONS] || [];
  const { isReadOnly } = useFieldGenerationContext();

  const ordersRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.ORDERS_RULES
    ] || [];
  const dailyPeriodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.DAILY_PERIOD_RULES
    ] || [];
  const periodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.PERIOD_RULES
    ] || [];

  const handleAddAction = (
    emptyAction: TCardIssuerActionBin | TCardIssuerActionScheme
  ) => {
    const currentValues = getIn(
      values,
      `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`
    );
    setFieldValue(`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`, [
      ...(currentValues || []),
      emptyAction
    ]);
  };

  const handleRemoveAction = (index: number) => {
    const currentValues: typeof actions = getIn(
      values,
      `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`
    );
    const filteredValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(
      `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`,
      filteredValues
    );
  };

  return (
    <>
      <BorderedBox mt="0">
        <Flex {...cardStyles}>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`
              ]
            }
          </Text>

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.DOMAIN}`}
          />
          <StoreField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.TYPE}`}
            options={purchaseConfirmationDocTypesOptions}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_LANGUAGE}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.COUNTRY_CODE}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_PREAUTH_AMOUNT}`}
          />

          <StoreField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.CURRENCY}`}
          />
        </Flex>
      </BorderedBox>

      <BorderedBox mt="0">
        <Flex {...cardStyles}>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="2rem"
          >
            {headers[`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`]}
          </Text>

          <StoreField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS}`}
          />

          <StoreField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_NEW_CLIENTS}`}
          />
        </Flex>
      </BorderedBox>

      <BorderedBox mt="0">
        <Text
          id={`${EStore.PARAMETERS}.${EParameters.SMS}`}
          fontSize="1rem"
          fontWeight="bold"
          m="0"
          mb="2rem"
        >
          {headers[`${EStore.PARAMETERS}.${EParameters.SMS}`]}
        </Text>
        <Flex
          direction="row"
          gap="1rem"
          columnGap="3rem"
          flexWrap="wrap"
          alignItems="flex-end"
        >
          {Object.values(EParameterPropertySms).map(smsKey => (
            <Box minWidth="20rem" maxWidth="20rem" key={smsKey}>
              <StoreField
                fieldType={EFieldType.SELECT}
                name={`${EStore.PARAMETERS}.${EParameters.SMS}.${smsKey}`}
                options={smsOptions[smsKey]}
              />
            </Box>
          ))}
        </Flex>
      </BorderedBox>

      <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
        <Text
          id={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`}
          fontSize="1rem"
          fontWeight="bold"
          m="0"
          mb="1rem"
        >
          {headers[`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`]}
        </Text>
        <StoreField
          fieldType={EFieldType.CHECKBOX}
          name={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.IMPORT_IMAGES_FROM_PIM}`}
        />
        <StoreField
          fieldType={EFieldType.CHECKBOX}
          name={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.AIFI_IMPORT_ENABLED}`}
        />
      </BorderedBox>

      <BorderedBox>
        <Flex alignItems="center" justifyContent="space-between" mb="2rem">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
          >
            {headers[`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`]}
          </Text>
          {!isReadOnly && (
            <Flex gap="1rem">
              <Button
                onClick={() => handleAddAction(emptyBinAction)}
                variant="ghost"
                leftIcon={<CirclePlusIcon size="1rem" />}
              >
                Card Issuer BIN Action
              </Button>
              <Button
                onClick={() => handleAddAction(emptyBrandAction)}
                variant="ghost"
                leftIcon={<CirclePlusIcon size="1rem" />}
              >
                Card Issuer Brand Action
              </Button>
            </Flex>
          )}
        </Flex>

        <Flex {...tilesContainerStyles}>
          {actions.map((_action, index) => (
            <Action
              key={index}
              index={index}
              onRemove={() => handleRemoveAction(index)}
            />
          ))}
          {!actions.length && <Text {...noDataStyles}>Brak akcji</Text>}
        </Flex>
      </BorderedBox>

      <BorderedBox mt="0">
        <Text
          id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`}
          fontSize="1rem"
          fontWeight="bold"
          m="0"
          mb="2rem"
        >
          {headers[`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]}
        </Text>

        <OrdersRules ordersRules={ordersRules} />
        <DailyPeriodRules dailyPeriodRules={dailyPeriodRules} />
        <PeriodRules periodRules={periodRules} />

        <Text
          id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}`}
          fontSize="0.8rem"
          fontWeight="bold"
          m="0"
          mt="2rem"
        >
          {
            headers[
              `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}`
            ]
          }
        </Text>
        <BorderedBox display="flex" flexDirection="column" gap="1rem">
          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}.${ECLientPriority.PRIORITY}`}
          />
        </BorderedBox>

        <Text
          id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`}
          fontSize="0.8rem"
          fontWeight="bold"
          m="0"
          mt="2rem"
        >
          {
            headers[
              `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`
            ]
          }
        </Text>
        <BorderedBox display="flex" flexDirection="column" gap="1rem">
          <StoreField
            isDisabled
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}.${ECLientPriority.ENABLED}`}
          />
        </BorderedBox>
      </BorderedBox>
    </>
  );
};

export default Parameters;
