import {
  EComplaintPreview,
  EPrice,
  EProducts,
  TProduct
} from "pages/complaint/methods/useGetComplaint/types";
import {
  EProductDetails,
  TProductDetails
} from "pages/complaint/methods/useGetProducts/types";

import {
  EComplaintDetails,
  EComplaintResult,
  EProcess,
  TPayload
} from "../../methods/useSendComplaintsReview/types";

export const mapper = (values: TPayload, currentProcess?: EProcess) => {
  if (currentProcess === EProcess.REFUND) {
    const { [EComplaintPreview.PRODUCTS]: _, ...restValues } = values;

    return {
      ...restValues
    };
  }
  if (currentProcess === EProcess.APPROVED) {
    return {
      [EComplaintDetails.RESULT]: EComplaintResult.APPROVED,
      ...values
    };
  }
  if (currentProcess === EProcess.REJECTED) {
    const { [EComplaintPreview.PRODUCTS]: _, ...restValues } = values;

    return {
      [EComplaintDetails.RESULT]: EComplaintResult.REJECTED,
      ...restValues
    };
  }
};

export const mapProduct = (product: TProductDetails): TProduct => {
  return {
    [EProducts.DISPLAY_NAME]: product[EProductDetails.DEFAULT_NAME],
    [EProducts.BARCODE]: product[EProductDetails.BARCODE],
    [EProducts.PRICE]: {
      [EPrice.TOTAL]: product[EProductDetails.DEFAULT_PRICE],
      [EPrice.CASH]: 0,
      [EPrice.LOYALTY_POINTS]: 0
    },
    [EProducts.QUANTITY]: 0
  };
};
