export function isProduction(): boolean {
  return process.env.REACT_APP_ENVIRONMENT === "prd";
}

export const getValue = ({
  values,
  name
}: {
  values: { [key: string]: any };
  name: string;
}) => {
  if (name.includes(".")) {
    const [mainKey, ...keyParts] = name.split(".");

    let value = values[mainKey];
    for (const keyPart of keyParts) {
      if (!keyPart) break;
      if (!value) value = "";
      value = value[keyPart as any];
    }

    return value;
  }

  return values[name];
};
