import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text
} from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { stickyHeadingStyles } from "pages/store/styles";
import { EComponent, EStore } from "types/configFields";

import Adyen from "../components/Adyen";
import Aifi from "../components/Aifi";
import Infokiosk from "../components/Infokiosk";
import Pos from "../components/Pos";
import PowerBouncer from "../components/PowerBouncer";
import { sectionHeaderStyles } from "../consts";

const Components = () => {
  return (
    <AccordionItem id="components">
      <AccordionButton
        mt="0.5rem"
        pb="1rem"
        {...stickyHeadingStyles}
        borderRadius=".5rem"
      >
        Konfiguracja komponentów w sklepie
        <AccordionIcon position="relative" />
      </AccordionButton>
      <AccordionPanel pt="0">
        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.AIFI}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja komponentu AiFi
          </Text>
          <Aifi />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.ADYEN}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja komponentu Adyen
          </Text>
          <Adyen />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.POS}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja komponentu POS
          </Text>
          <Pos />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja komponentu Infokiosk
          </Text>
          <Infokiosk />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja komponentu Power Bouncer
          </Text>
          <PowerBouncer />
        </BorderedBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Components;
