import {
  ECLientPriority,
  EComponent,
  EComponentProperty,
  EDay,
  EIssuerActionProperty,
  EIssuerActionType,
  EMonitor,
  EParameterProperty,
  EParameterPropertySms,
  EParameters,
  EProperty,
  ESchedule,
  EStore,
  EStoreService,
  EStoreServiceProperty,
  EStoreState,
  EStoreType,
  TMonitor,
  TStore,
  TStoreComponents,
  TStoreParameters,
  TStoreServices,
  EComponentType,
  EMessage,
  EMessageNonDisable,
  EPowerBouncerStatus,
  EPurchaseConfirmationDocType
} from "types/configFields";

export const typesOptions = Object.values(EStoreType).map(value => ({
  value,
  label: value
}));

export const statesOptions = Object.values(EStoreState).map(value => ({
  value,
  label: value
}));

export const purchaseConfirmationDocTypesOptions = Object.values(
  EPurchaseConfirmationDocType
).map(value => ({
  value,
  label: value
}));

export const booleanOptions = [
  { value: true, label: "TRUE" },
  { value: false, label: "FALSE" }
];

export const messageOptions = Object.values(EMessage).map(value => ({
  value,
  label: value
}));

export const messageNonDisableOptions = Object.values(EMessageNonDisable).map(
  value => ({
    value,
    label: value
  })
);

export const componentTypesOptions = Object.values(EComponentType).map(
  value => ({
    value,
    label: value
  })
);

export const powerBouncerStatusOptions = Object.values(EPowerBouncerStatus).map(
  value => ({
    value,
    label: value
  })
);

export const smsOptions = {
  [EParameterPropertySms.WELCOME_SMS]: messageOptions,
  [EParameterPropertySms.RECEIPT_SMS]: messageOptions,
  [EParameterPropertySms.EXIT_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_FAILED_SMS]: messageOptions,
  [EParameterPropertySms.PBL_REQUESTED_SMS]: messageNonDisableOptions,
  [EParameterPropertySms.ACTIVATION_SMS]: messageNonDisableOptions,
  [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: messageOptions,
  [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: messageOptions,
  [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: messageOptions
};

export const emptyOrderRule = {
  [ECLientPriority.PRIORITY]: "",
  [ECLientPriority.ORDERS_THRESHOLD]: ""
};

export const emptyPeriodRule = {
  [ECLientPriority.STARTS_AT]: "",
  [ECLientPriority.ENDS_AT]: "",
  [ECLientPriority.PRIORITY]: ""
};

export const emptyBinAction = {
  isBin: true,
  [EIssuerActionProperty.CARD_ISSUER_BIN]: "",
  [EIssuerActionProperty.ACTION]: {
    [EIssuerActionProperty.TYPE]: EIssuerActionType.OVERRIDE_PREAUTH,
    [EIssuerActionProperty.PREAUTH_AMOUNT]: ""
  }
};

export const emptyBrandAction = {
  isBin: false,
  [EIssuerActionProperty.CARD_ISSUER_BRAND]: "",
  [EIssuerActionProperty.ACTION]: {
    [EIssuerActionProperty.TYPE]: EIssuerActionType.OVERRIDE_PREAUTH,
    [EIssuerActionProperty.PREAUTH_AMOUNT]: ""
  }
};

export const emptyDateValues = {
  [ESchedule.ADJUSTED_DAYS]: {
    [EProperty.DAY]: "",
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [ESchedule.CLOSED_DAYS]: ""
};

export const emptyClientPriority = {
  [ECLientPriority.ORDERS_RULES]: [],
  [ECLientPriority.DAILY_PERIOD_RULES]: [],
  [ECLientPriority.PERIOD_RULES]: [],
  [ECLientPriority.VIP_RULE]: {
    [ECLientPriority.ENABLED]: undefined
  },
  [ECLientPriority.STORE_RULE]: {
    [ECLientPriority.PRIORITY]: ""
  }
};

export const emptyActivation = {
  [EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS]: undefined,
  [EParameterProperty.REQUIRE_FOR_NEW_CLIENTS]: undefined
};

const emptyParameters: TStoreParameters = {
  [EParameters.PURCHASE_CONFIRMATION_DOC]: {
    [EParameterProperty.TYPE]: "",
    [EParameterProperty.DOMAIN]: ""
  },
  [EParameters.DEFAULT_LANGUAGE]: "",
  [EParameters.COUNTRY_CODE]: "",
  [EParameters.DEFAULT_PREAUTH_AMOUNT]: "",
  [EParameters.APP_PREAUTH_ENABLED]: undefined,
  [EParameters.CURRENCY]: "",
  [EParameters.ACTIVATION]: emptyActivation,
  [EParameters.SMS]: {
    [EParameterPropertySms.WELCOME_SMS]: "",
    [EParameterPropertySms.RECEIPT_SMS]: "",
    [EParameterPropertySms.EXIT_SMS]: "",
    [EParameterPropertySms.PAYMENT_FAILED_SMS]: "",
    [EParameterPropertySms.PBL_REQUESTED_SMS]: "",
    [EParameterPropertySms.ACTIVATION_SMS]: "",
    [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: "",
    [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: "",
    [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: ""
  },
  [EParameters.PRODUCT_IMPORT]: {
    [EParameterProperty.IMPORT_IMAGES_FROM_PIM]: undefined,
    [EParameterProperty.AIFI_IMPORT_ENABLED]: undefined
  },
  [EParameters.CARD_ISSUER_ACTIONS]: [],
  [EParameters.CLIENT_PRIORITY]: emptyClientPriority
};

const emptyMonitor: TMonitor = {
  [EMonitor.IS_ENABLED]: undefined,
  [EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS]: undefined,
  [EMonitor.ACTIONS]: {
    [EMonitor.STORE_STATE_MODIFIER]: {
      [EMonitor.IS_ACTIVE]: undefined,
      [EMonitor.CLOSE_THRESHOLD]: "",
      [EMonitor.CLOSE_PERIOD]: "",
      [EMonitor.OPEN_THRESHOLD]: "",
      [EMonitor.OPEN_PERIOD]: ""
    }
  }
};

const emptyComponents: TStoreComponents = {
  [EComponent.AIFI]: {
    [EComponentProperty.AIFI_ID]: "",
    [EComponentProperty.ENTRY_ID]: "",
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.STATE]: emptyMonitor
    }
  },
  [EComponent.ADYEN]: {
    [EComponentProperty.TERMINAL_ID]: "",
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor
    }
  },
  [EComponent.POS]: {
    [EComponentProperty.SERVICE_KEY]: "",
    [EComponentProperty.TYPE]: "",

    [EComponentProperty.POS_SERVICE]: {
      [EComponentProperty.WORKER_ID]: "",
      [EComponentProperty.POS_IP_ADDRESS]: "",
      [EComponentProperty.POS_PORT]: "",
      [EComponentProperty.POS_USERNAME]: "",
      [EComponentProperty.POS_PASSWORD]: "",
      [EComponentProperty.OUTAGE_THRESHOLD]: "",
      [EComponentProperty.NO_OUTAGE_THRESHOLD]: ""
    },
    [EComponentProperty.IPOS]: {
      [EComponentProperty.TRONITAG_ID]: "",
      [EComponentProperty.FAKTUROWNIA_ID]: ""
    },
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor,
      [EComponentProperty.PAPER_STATE]: emptyMonitor
    }
  },
  [EComponent.INFOKIOSK]: {
    [EComponentProperty.SCOPE]: "",
    [EComponentProperty.SERVICE_KEY]: "",
    [EComponentProperty.HEARTBEAT_THRESHOLD]: "",
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor
    }
  },
  [EComponent.POWER_BOUNCER]: {
    [EComponentProperty.STATUS]: "",
    [EComponentProperty.TOKEN]: "",
    [EComponentProperty.HELP_LINK]: "",
    [EComponentProperty.HEARTBEAT_THRESHOLD]: "",
    [EComponentProperty.IO_CONTROLLER]: {
      [EComponentProperty.SERVICE_KEY]: ""
    },
    [EComponentProperty.AGE_ESTIMATION]: {
      [EComponentProperty.MIN_ADULT_AGE]: ""
    },
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor,
      [EComponentProperty.QR_READER_AVAILABILITY]: emptyMonitor
    }
  }
};

const emptyServices: TStoreServices = {
  [EStoreService.ERP]: {
    [EStoreServiceProperty.STORE_ID]: "",
    [EStoreServiceProperty.SERVICE_KEY]: "",
    [EStoreServiceProperty.IS_ENABLED]: undefined
  },
  [EStoreService.NANO_MONITOR]: {
    [EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL]: ""
  }
};

export const emptyBase = {
  [EDay.MON]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.TUE]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.WED]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.THU]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.FRI]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.SAT]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.SUN]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  }
};

export const initialStore: TStore = {
  [EStore.STORE_ID]: "",
  [EStore.CHAIN_ID]: "",
  [EStore.EXTERNAL_STORE_ID]: "",
  [EStore.NAME]: "",
  [EStore.TYPE]: EStoreType.REGULAR,
  [EStore.STATE]: EStoreState.PRE_LIVE,
  [EStore.ADDRESS]: "",
  [EStore.MANAGER_EMAIL]: "",
  [EStore.PARAMETERS]: emptyParameters,
  [EStore.COMPONENTS]: emptyComponents,
  [EStore.SERVICES]: emptyServices,
  [EStore.OPENING_SCHEDULE]: null
};
