import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Button, Collapse, Flex } from "@chakra-ui/react";

import { DownloadCsvButton } from "components/downloadCsvButton/DownloadCsvButton";
import { FilterIcon } from "components/icons/FilterIcon";
import { ColumnSwitcher } from "components/table/columnSwitcher/ColumnSwitcher";
import { useTableContext } from "contexts/TableContext";

import { columnsOptions } from "../constants";
import { EComplaint, TComplaint } from "../methods/useGetComplaints/types";

import { Filters } from "./Filters";
import { Search } from "./Search";

type TProps = { data: TComplaint[] };

export function TopSection({ data }: TProps) {
  const { getCheckboxProps, checkedRows } = useTableContext();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);

  const getData = () => {
    const dataToSave = checkedRows.map(id =>
      data.find(item => item[EComplaint.ID] === id)
    );

    return dataToSave;
  };

  const handleToggleFilters = () =>
    setIsFiltersOpen(prevIsFiltersOpen => !prevIsFiltersOpen);

  const handleToggleColumnsSelector = () =>
    setIsColumnSelectorOpen(
      prevIsColumnSelectorOpen => !prevIsColumnSelectorOpen
    );

  return (
    <>
      <Flex gap=".5rem" mb=".5rem" justifyContent="space-between">
        <Search />

        <Flex gap="0rem">
          <Button
            onClick={handleToggleFilters}
            p="0 15px"
            // leftIcon={isFiltersOpen ? <ViewOffIcon /> : <ViewIcon />}
            leftIcon={<FilterIcon w=".75rem" />}
            variant="ghost"
          >
            Filtry
          </Button>

          <Button
            onClick={handleToggleColumnsSelector}
            p="0 15px"
            justifySelf="end"
            leftIcon={isColumnSelectorOpen ? <ViewOffIcon /> : <ViewIcon />}
            variant="ghost"
          >
            Kolumny
          </Button>
          <DownloadCsvButton
            getData={getData}
            isDisabled={!checkedRows.length}
          />
        </Flex>
      </Flex>
      <Box
        as={Collapse}
        in={isFiltersOpen}
        animateOpacity
        overflow="visible !important"
      >
        <Filters />
      </Box>

      <ColumnSwitcher
        isOpen={isColumnSelectorOpen}
        isButtonVisible={false}
        options={columnsOptions}
        getCheckboxProps={getCheckboxProps}
      />
    </>
  );
}
