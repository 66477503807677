import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import { Property } from "components/property";

import { TTabProps } from "../ComplaintPreview";
import {
  EComplaintPreview,
  EOrder,
  EProperty,
  EReviewResult
} from "../methods/useGetComplaint/types";
import {
  EUserOrderDetails,
  EUserOrderProperties
} from "../methods/useGetUserOrderDetails/types";
import useGetUserOrderDetails from "../methods/useGetUserOrderDetails/useGetUserOrderDetails";

export function Orders({ complaint, isLoading }: TTabProps) {
  const userOrderDetails = useGetUserOrderDetails({
    orderId: complaint[EComplaintPreview.ORDER_ID],
    userId: complaint[EComplaintPreview.USER_ID]
  });

  useEffect(() => {
    userOrderDetails.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" gap="1rem">
      <Property label="ID Zamówienia" isLoading={userOrderDetails.isLoading}>
        {userOrderDetails.data?.[EUserOrderDetails.ID]}
      </Property>
      <Property label="Numer paragonu" isLoading={userOrderDetails.isLoading}>
        {
          userOrderDetails.data?.[EUserOrderDetails.FISCAL_RECEIPT][
            EUserOrderProperties.TRANSACTION_NUMBER
          ]
        }
      </Property>
      <Property label="Data transakcji" isLoading={userOrderDetails.isLoading}>
        {userOrderDetails.data?.[EUserOrderDetails.CREATED_AT]}
      </Property>

      <Property label="Sklep" isLoading={isLoading}>
        {complaint[EComplaintPreview.STORE][EProperty.EXTERNAL_ID]}
      </Property>

      <Property label="Oryginalna kwota" isLoading={userOrderDetails.isLoading}>
        {
          userOrderDetails.data?.[EUserOrderDetails.PRICE][
            EUserOrderProperties.ORIGINAL
          ][EUserOrderProperties.TOTAL]
        }
      </Property>
      <Property
        label="Kwota pobrana od klienta"
        isLoading={userOrderDetails.isLoading}
      >
        {
          userOrderDetails.data?.[EUserOrderDetails.PRICE][
            EUserOrderProperties.ORIGINAL
          ][EUserOrderProperties.CASH]
        }
      </Property>

      <Property label="Poprawna kwota rachunku" isLoading={isLoading}>
        {complaint[EComplaintPreview.REVIEW_RESULT][EReviewResult.CASH_AMOUNT]}
      </Property>

      <Property label="Kwota zwrotu" isLoading={isLoading}>
        {
          complaint[EComplaintPreview.REVIEW_RESULT][EReviewResult.REFUND][
            EProperty.AMOUNT
          ]
        }
      </Property>

      <Property
        label="Waluta zamówienia"
        isLoading={userOrderDetails.isLoading}
      >
        {
          userOrderDetails.data?.[EUserOrderDetails.PRICE][
            EUserOrderProperties.ORIGINAL
          ][EUserOrderProperties.CURRENCY]
        }
      </Property>

      <Property label="Zlecono zwrot środków" isLoading={isLoading}>
        {
          complaint[EComplaintPreview.REVIEW_RESULT][EReviewResult.REFUND][
            EProperty.ISSUING_STATUS
          ]
        }
      </Property>

      <Property label="ID sesji AiFi" isLoading={userOrderDetails.isLoading}>
        {userOrderDetails.data?.[EUserOrderDetails.AIFI_SESSION_ID]}
      </Property>

      <Property label="Pobrana preautoryzacja" isLoading={isLoading}>
        {complaint[EComplaintPreview.ORDER][EOrder.PRE_AUTH_CAPTURED_AMOUNT]}
      </Property>

      <Property label="Naliczone bony" isLoading={isLoading}>
        {!!complaint[EComplaintPreview.HAS_COUPON] ? "Tak" : "Nie"}
      </Property>
    </Flex>
  );
}
