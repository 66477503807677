import { useEffect } from "react";
import { FailedFetchingAlert } from "components";
import { Form, Formik } from "formik";
import { mockComplaintsResponse } from "mocks/complaintsMocks";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import { TableContextProvider } from "contexts/TableContext";

import { List } from "./components/List";
import { PaginationSection } from "./components/PaginationSection";
import { TopSection } from "./components/TopSection";
import { initialValues, columns } from "./constants";
import { complaintsMapper } from "./helpers";
import {
  EComplaintResponse,
  TQueryParams
} from "./methods/useGetComplaints/types";
import { EComplaint } from "./methods/useGetComplaints/types";
import useGetComplaints from "./methods/useGetComplaints/useGetComplaints";

export function Complaints() {
  const getComplaints = useGetComplaints();

  useEffect(() => {
    getComplaints.fetchData({ queryParams: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows =
    getComplaints.data?.[EComplaintResponse.COMPLAINTS].map(
      complaint => complaint[EComplaint.ID]
    ) || [];
  const data = complaintsMapper(mockComplaintsResponse.complaints);

  const handleSubmit = (values: TQueryParams) => {
    getComplaints.fetchData({ queryParams: values });
  };

  if (getComplaints.error)
    return (
      <FailedFetchingAlert mt="30px" message="Nieudane pobieranie reklamacji" />
    );

  return (
    <>
      {getComplaints.data && (
        <TableContextProvider defaultVisibleColumns={columns} rows={rows}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <TopSection data={data} />

              <PaginationSection>
                <BorderedBox mt="0">
                  {getComplaints.isLoading ? (
                    <CustomSpinner />
                  ) : (
                    <List data={data} />
                  )}
                </BorderedBox>
              </PaginationSection>
            </Form>
          </Formik>
        </TableContextProvider>
      )}
    </>
  );
}
