import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Stack,
  Text
} from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { FieldCustomCheckbox } from "components/customCheckbox/FieldCustomCheckbox";
import { FieldCustomCheckboxGroup } from "components/customCheckbox/FieldCustomCheckboxGroup";
import { FieldCustomDatePicker } from "components/customDatePicker/FieldCustomDatePicker";
import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import {
  refoundRequestedOptions,
  sourceOptions,
  statusOptions,
  storesOptions
} from "../constants";
import { EQueryParams, TQueryParams } from "../methods/useGetComplaints/types";

export function Filters() {
  const { resetForm } = useFormikContext<TQueryParams>();

  const handleReset = () => {
    resetForm();
  };

  const columnStyle = {
    direction: "column",
    gap: "1rem",
    width: "100%"
  } as const;

  return (
    <BorderedBox display="flex" gap="2rem" marginBlock="0 1rem">
      <Flex {...columnStyle}>
        <Text>Data utworzenia</Text>
        <FieldWrapper name={EQueryParams.CREATED_AFTER} label="po" w="100%">
          <FieldCustomDatePicker name={EQueryParams.CREATED_AFTER} isTime />
        </FieldWrapper>

        <FieldWrapper name={EQueryParams.CREATED_BEFORE} label="przed" w="100%">
          <FieldCustomDatePicker name={EQueryParams.CREATED_BEFORE} isTime />
        </FieldWrapper>
      </Flex>

      <Divider orientation="vertical" height="auto" />

      <Flex {...columnStyle}>
        <Text>Szczegóły</Text>
        <FieldWrapper name={EQueryParams.STORE_ID} label="Sklep">
          <FieldCustomSelect
            isMulti
            name={EQueryParams.STORE_ID}
            options={storesOptions}
            placeholder="wybierz sklepy"
          />
        </FieldWrapper>

        <FieldWrapper
          name={EQueryParams.REFUND_ISSUING_STATUS}
          label="Zlecono zwrot środków?"
        >
          <FieldCustomSelect
            name={EQueryParams.REFUND_ISSUING_STATUS}
            options={refoundRequestedOptions}
            placeholder="czy zlecono"
          />
        </FieldWrapper>

        <FieldWrapper name={EQueryParams.SOURCE} label="Źródło reklamacji">
          <FieldCustomSelect
            name={EQueryParams.SOURCE}
            options={sourceOptions}
            placeholder="wybierz źródło"
          />
        </FieldWrapper>
      </Flex>

      <Divider orientation="vertical" height="auto" />

      <Flex {...columnStyle}>
        <Text>Status</Text>
        <Stack>
          <FieldCustomCheckboxGroup
            options={statusOptions}
            name={EQueryParams.STATUS}
          />
        </Stack>

        <Text>Czy została naliczona promocja?</Text>
        <FieldWrapper name={EQueryParams.HAS_COUPON} label="Tak" isCheckbox>
          <FieldCustomCheckbox name={EQueryParams.HAS_COUPON} />
        </FieldWrapper>

        <ButtonGroup mt="auto" ml="auto">
          <Button onClick={handleReset} variant="outline" width="auto">
            Wyczyść filtry
          </Button>
          <Button type="submit" variant="solid" width="6rem">
            Filtruj
          </Button>
        </ButtonGroup>
      </Flex>
    </BorderedBox>
  );
}
