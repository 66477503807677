import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex
} from "@chakra-ui/react";
import { ComponentPropertyStatus } from "api/common/storeComponentProperty";
import { FailedFetchingAlert } from "components";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { TableBottomSection } from "components/tableBottomSection/TableBottomSection";
import { useRoles } from "hooks/roles/useRoles";
import { useSearch } from "hooks/useSearch";
import { farLeftCellStyle, farRightCellStyle } from "utils/commonStyles";

import { LockFridgeButton } from "./components/LockFridgeButton";
import { MaintenanceMode } from "./components/MaintenanceMode";
import { PowerBouncerModeModal } from "./components/PowerBouncerModeModal";
import { TopSection } from "./components/TopSection";
import { useEnergyDrinksStoresData } from "./useEnergyDrinksStoresData";
import { getFilteredStores } from "./utils";

export type TFilters = {
  isWithoutToken: boolean;
};

export function EnergyDrinks(): JSX.Element {
  const { stores, isLoadingFetchStores, error, onPowerBouncerStatusChange } =
    useEnergyDrinksStoresData();
  const { searchValue, changeSearchValue, clearSearch } = useSearch();
  const [filters, setFilters] = useState<TFilters>({
    isWithoutToken: true
  });
  const filteredStores = getFilteredStores({
    stores,
    pattern: searchValue,
    isWithoutToken: filters.isWithoutToken
  });

  const {
    canChangeEnergyDrinksServiceMode,
    canLockEnergyDrinks,
    canReadEnergyDrinks
  } = useRoles();
  if (error) {
    return <FailedFetchingAlert />;
  }

  return (
    <>
      <TopSection
        {...{
          searchValue,
          changeSearchValue,
          clearSearch,
          setFilters,
          filters
        }}
      />

      <BorderedBox>
        {!!filteredStores.length && (
          <TableContainer maxWidth="100vw" overflowX="auto">
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th lineHeight="2rem" {...farLeftCellStyle}>
                    MPK
                  </Th>
                  <Th>Nazwa</Th>
                  <Th>Adres</Th>
                  {canReadEnergyDrinks && (
                    <Th {...farRightCellStyle} right="7rem">
                      Status
                    </Th>
                  )}
                  {(canLockEnergyDrinks ||
                    canChangeEnergyDrinksServiceMode) && (
                    <Th {...farRightCellStyle}>Akcja</Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {filteredStores.map(store => (
                  <Tr key={store.storeId} height="60px">
                    <Td {...farLeftCellStyle}>
                      <span title={store.storeId}>{store.externalStoreId}</span>
                    </Td>
                    <Td>{store.name}</Td>
                    <Td>{store.address}</Td>
                    {canReadEnergyDrinks && (
                      <Td {...farRightCellStyle} right="7rem">
                        <MaintenanceMode
                          currentStatus={store.powerBouncerStatus}
                          isLoading={store.isLoadingPowerBouncerStatus}
                        />
                      </Td>
                    )}

                    <Td {...farRightCellStyle}>
                      <Flex gap="0.5rem">
                        {canChangeEnergyDrinksServiceMode && (
                          <PowerBouncerModeModal
                            currentStatus={store.powerBouncerStatus}
                            storeId={store.storeId}
                            onChange={onPowerBouncerStatusChange}
                            mpk={store.externalStoreId}
                            label="Zmień tryb"
                          />
                        )}
                        {canLockEnergyDrinks && (
                          <LockFridgeButton
                            storeId={store.storeId}
                            mpk={store.name}
                            isDisabled={
                              store.powerBouncerStatus ===
                              ComponentPropertyStatus.MAINTENANCE
                            }
                          />
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <TableBottomSection
          isLoading={isLoadingFetchStores}
          noData={!filteredStores.length}
          entityName="sklepów"
        />
      </BorderedBox>
    </>
  );
}
