import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link as ChakraLink,
  Checkbox
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { HidableCell } from "components/table/columnSwitcher/HidableCell";
import { useTableContext } from "contexts/TableContext";
import { farRightCellStyle } from "utils/commonStyles";
import { getValueByPath } from "utils/getValueByPath";

import { columns, labels } from "../constants";
import {
  EComplaint,
  EOrder,
  TComplaint
} from "../methods/useGetComplaints/types";

import { StatusBadge } from "./StatusBadge";

type TProps = { data: TComplaint[] };

export function List({ data }: TProps) {
  const {
    getIsVisible,
    getRowsCheckboxProps,
    toggleCheckAllRows,
    isAllChecked
  } = useTableContext();

  return (
    <TableContainer maxWidth="100vw" overflowX="auto">
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <HidableCell as={Th} isVisible={true}>
              <Checkbox
                onChange={toggleCheckAllRows}
                isChecked={isAllChecked}
              />
            </HidableCell>

            {columns.map(columnKey => (
              <HidableCell
                as={Th}
                key={columnKey}
                isVisible={getIsVisible(columnKey)}
              >
                {labels[columnKey as keyof typeof labels]}
              </HidableCell>
            ))}
            <Th {...farRightCellStyle} height="2.5rem"></Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.map(complaint => (
            <Tr key={complaint[EComplaint.ID]} height="60px">
              <HidableCell as={Td} isVisible={true}>
                <Checkbox
                  {...getRowsCheckboxProps({
                    value: complaint[EComplaint.ID]
                  })}
                />
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(EComplaint.ID)}>
                <ChakraLink
                  as={Link}
                  to={complaint[EComplaint.ID]}
                  color="blue.600"
                >
                  {complaint[EComplaint.ID]}
                </ChakraLink>
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(EComplaint.STATUS)}>
                <StatusBadge status={complaint[EComplaint.STATUS]} />
              </HidableCell>

              <HidableCell
                as={Td}
                isVisible={getIsVisible(EComplaint.REFUND_ISSUING_STATUS)}
              >
                {complaint[EComplaint.REFUND_ISSUING_STATUS]}
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(EComplaint.USER_ID)}>
                <ChakraLink
                  as={Link}
                  to={complaint[EComplaint.USER_ID]}
                  color="blue.600"
                >
                  {complaint[EComplaint.USER_ID]}
                </ChakraLink>
              </HidableCell>

              {[
                EComplaint.EMAIL,
                EComplaint.STORE_ID,
                `${EComplaint.ORDER}.${EOrder.AIFI_ID}`,
                `${EComplaint.ORDER}.${EOrder.CASH_AMOUNT}`,
                EComplaint.CORRECTED_ORDER_CASH_AMOUNT,
                EComplaint.SOURCE,
                `${EComplaint.ORDER}.${EOrder.CURRENCY}`,
                `${EComplaint.ORDER}.${EOrder.HAS_COUPON}`
              ].map(columnKey => (
                <HidableCell
                  as={Td}
                  key={`${complaint[EComplaint.ID]}-${columnKey}`}
                  isVisible={getIsVisible(columnKey)}
                >
                  {String(getValueByPath(columnKey, complaint))}
                </HidableCell>
              ))}
              <Td {...farRightCellStyle}>
                <Button as={Link} to={complaint[EComplaint.ID]}>
                  Podgląd
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
