import { useEffect } from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Field, getIn, useFormikContext } from "formik";

import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import { EComplaintPreview } from "pages/complaint/methods/useGetComplaint/types";

import { EComplaintDetails } from "../../../../methods/useSendComplaintsReview/types";

type TProps = {
  isRejected?: boolean;
  setProcess: () => void;
  isLoading: boolean;
};

const ComplaintReviewModal = ({
  isRejected,
  setProcess,
  isLoading
}: TProps) => {
  const modal = useDisclosure();
  const { submitForm, errors } = useFormikContext();

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  const handleReject = () => {
    setProcess();
    submitForm();
  };
  const handleAcceptNoRefund = () => {
    setProcess();
    submitForm();
  };

  return (
    <>
      {isRejected ? (
        <Button onClick={modal.onOpen} leftIcon={<CloseIcon />} variant="solid">
          Odrzuć reklamację
        </Button>
      ) : (
        <Button onClick={modal.onOpen} leftIcon={<CheckIcon />} variant="solid">
          Zaakceptuj reklamację bez zwrotu środków
        </Button>
      )}

      <Modal {...modal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            {isRejected ? (
              <Text as="h2">Odczuć reklamację.</Text>
            ) : (
              <Text as="h2">Zaakceptuj reklamację.</Text>
            )}
          </ModalHeader>
          <ModalBody>
            {isRejected ? (
              <Flex direction="column" gap="1rem">
                <FieldWrapper
                  name={EComplaintDetails.REASON}
                  label="Ewentualny powód negatywnego rozpatrzenia"
                >
                  <Field as={Input} name={EComplaintDetails.REASON} />
                </FieldWrapper>
              </Flex>
            ) : (
              <Flex direction="column" gap="1rem">
                {!!getIn(errors, EComplaintPreview.PRODUCTS) && (
                  <Text color="red.500">sprawdź błędy w tabeli</Text>
                )}
              </Flex>
            )}
          </ModalBody>
          <ModalFooter display="flex" gap="1rem">
            <Button
              isLoading={isLoading}
              onClick={isRejected ? handleReject : handleAcceptNoRefund}
              colorScheme={isRejected ? "red" : "green"}
              variant="solid"
              minWidth="6rem"
            >
              {isRejected ? "Odrzucam" : "Akceptuję"}
            </Button>

            <Button
              isDisabled={isLoading}
              onClick={modal.onClose}
              variant="ghost"
              minWidth="6rem"
            >
              Anuluj
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComplaintReviewModal;
