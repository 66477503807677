import { useState } from "react";
import { Search2Icon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Td,
  Tr,
  Link as ChakraLink,
  Input,
  useToast
} from "@chakra-ui/react";
import { Field, getIn, useFormikContext } from "formik";
import { Link } from "react-router-dom";

import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import { EProperty } from "pages/complaint/methods/useGetProducts/types";
import useGetProducts from "pages/complaint/methods/useGetProducts/useGetProducts";

import {
  EComplaintPreview,
  EPrice,
  EProducts,
  TProduct
} from "../../methods/useGetComplaint/types";

import { mapProduct } from "./mappers";

type TProps = {
  product: TProduct;
  index: number;
  storeId: string;
};

const Row = ({ product, index, storeId }: TProps) => {
  const { errors, values, setFieldValue } = useFormikContext<{
    [EComplaintPreview.PRODUCTS]: TProduct[];
  }>();
  const [productId, setProductId] = useState("");
  const { fetchData } = useGetProducts({
    storeId,
    productId
  });

  const toast = useToast();

  const handleSearchProduct = async (index: number) => {
    const response = await fetchData();

    if (!response.data)
      return toast({
        status: "error",
        title: "Wystąpił błąd"
      });

    const result = response.data[EProperty.RESULT];

    if (!result || result?.error)
      return toast({
        status: "error",
        title: "Nie znaleziono produktu"
      });

    const newValues = [...values[EComplaintPreview.PRODUCTS]];
    newValues[index] = mapProduct(result);
    setFieldValue(EComplaintPreview.PRODUCTS, newValues);
  };

  return (
    <Tr>
      <Td>
        <Flex gap=".5rem">
          <Input
            type="text"
            placeholder="ID produktu"
            value={productId}
            onChange={e => setProductId(e.target.value)}
          />
          <Button
            isDisabled={!productId}
            onClick={() => handleSearchProduct(index)}
            variant="solid"
            minW="2.5rem"
          >
            <Search2Icon />
          </Button>
        </Flex>
      </Td>
      <Td>
        <FieldWrapper
          name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.BARCODE}`}
          errorMessage={getIn(
            errors,
            `${EComplaintPreview.PRODUCTS}.${index}.${EProducts.BARCODE}`
          )}
        >
          <ChakraLink
            as={Link}
            to={`/products/${product[EProducts.BARCODE]}`}
            color="blue.600"
          >
            {product[EProducts.DISPLAY_NAME]}
          </ChakraLink>
        </FieldWrapper>
      </Td>
      <Td>
        <FieldWrapper
          name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.BARCODE}`}
          errorMessage={getIn(
            errors,
            `${EComplaintPreview.PRODUCTS}.${index}.${EProducts.BARCODE}`
          )}
        >
          {product[EProducts.BARCODE]}
        </FieldWrapper>
      </Td>
      <Td>
        <FieldWrapper
          name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.TOTAL}`}
          errorMessage={getIn(
            errors,
            `${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.TOTAL}`
          )}
        >
          {product[EProducts.PRICE][EPrice.TOTAL]}
        </FieldWrapper>
      </Td>

      <Td>
        <FieldWrapper
          name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.CASH}`}
          errorMessage={getIn(
            errors,
            `${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.CASH}`
          )}
        >
          <Field
            type="number"
            as={Input}
            name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.CASH}`}
          />
        </FieldWrapper>
      </Td>
      <Td>
        <FieldWrapper
          name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.LOYALTY_POINTS}`}
          errorMessage={getIn(
            errors,
            `${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.LOYALTY_POINTS}`
          )}
        >
          <Field
            type="number"
            as={Input}
            name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.PRICE}.${EPrice.LOYALTY_POINTS}`}
          />
        </FieldWrapper>
      </Td>
      <Td>
        <FieldWrapper
          name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.QUANTITY}`}
          errorMessage={getIn(
            errors,
            `${EComplaintPreview.PRODUCTS}.${index}.${EProducts.QUANTITY}`
          )}
        >
          <Field
            type="number"
            as={Input}
            name={`${EComplaintPreview.PRODUCTS}.${index}.${EProducts.QUANTITY}`}
          />
        </FieldWrapper>
      </Td>
    </Tr>
  );
};

export default Row;
