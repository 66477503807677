import * as Yup from "yup";

import { EStoreService, EStoreServiceProperty } from "types/configFields";

import { requiredMessage } from "./consts";

export const servicesSchema = Yup.object()
  .optional()
  .shape({
    [EStoreService.ERP]: Yup.object()
      .optional()
      .shape({
        [EStoreServiceProperty.STORE_ID]:
          Yup.string().required(requiredMessage),
        [EStoreServiceProperty.SERVICE_KEY]: Yup.string(),
        [EStoreServiceProperty.IS_ENABLED]: Yup.boolean()
      }),
    [EStoreService.NANO_MONITOR]: Yup.object()
      .optional()
      .shape({
        [EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL]: Yup.string().email(
          "Podaj poprawny adres email"
        )
      })
  });
