import { Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import { componentTypesOptions } from "pages/store/consts";
import {
  EComponent,
  EComponentProperty,
  EComponentType,
  EStore,
  TStore
} from "types/configFields";

import { cardStyles } from "../../styles";

import MonitorFields from "./MonitorFields";

const Pos = () => {
  const { values } = useFormikContext<TStore>();
  const keyPart = `${EStore.COMPONENTS}.${EComponent.POS}`;
  const currentType =
    values[EStore.COMPONENTS]?.[EComponent.POS][EComponentProperty.TYPE];
  const isPosService = (currentType as any) === EComponentType.POS_SERVICE;
  const isIpos = (currentType as any) === EComponentType.IPOS;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.SERVICE_KEY}`}
        />

        <StoreField
          fieldType={EFieldType.SELECT}
          name={`${keyPart}.${EComponentProperty.TYPE}`}
          options={componentTypesOptions}
        />
      </Flex>

      {isPosService && (
        <>
          <Flex {...cardStyles}>
            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.WORKER_ID}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_IP_ADDRESS}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_PORT}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_USERNAME}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_PASSWORD}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.OUTAGE_THRESHOLD}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.NO_OUTAGE_THRESHOLD}`}
            />
          </Flex>

          <Flex w="full" gap="1rem">
            <MonitorFields
              title="Availability monitor"
              monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
            />

            <MonitorFields
              title="Paper state monitor"
              monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.PAPER_STATE}`}
            />
          </Flex>
        </>
      )}

      {isIpos && (
        <Flex {...cardStyles}>
          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.IPOS}.${EComponentProperty.TRONITAG_ID}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.IPOS}.${EComponentProperty.FAKTUROWNIA_ID}`}
          />
        </Flex>
      )}
    </>
  );
};

export default Pos;
