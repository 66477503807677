import { DownloadIcon } from "@chakra-ui/icons";
import { Button, ChakraProps } from "@chakra-ui/react";

import { TDataFormat, createCsvFile } from "utils/createCsvFile";

type TProps = {
  isDisabled?: boolean;
  getData: () => TDataFormat;
} & ChakraProps;

export function DownloadCsvButton({
  isDisabled,
  getData,
  ...restProps
}: TProps) {
  const handleDownload = () => {
    const data = getData();

    if (!data?.length) return;

    const blob = createCsvFile(data);
    const objUrl = URL.createObjectURL(blob);

    window.open(objUrl, "_blank");
  };

  return (
    <>
      <Button
        onClick={handleDownload}
        isDisabled={isDisabled}
        leftIcon={<DownloadIcon />}
        w="max-content"
        variant="ghost"
        {...restProps}
      >
        Zapisz do CSV
      </Button>
    </>
  );
}
