import { useEffect, useState } from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Button, Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

import { TStore } from "types/configFields";

type TProps = {
  isLoading: boolean;
};

const FieldsFooter = ({ isLoading }: TProps) => {
  const { isValid, isValidating, isSubmitting } = useFormikContext<TStore>();
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);
  const [isErrorInfoVisible, setIsErrorInfoVisible] = useState(false);

  useEffect(() => {
    if (!isValid && isSubmitting) setIsErrorInfoVisible(true);
  }, [isValid, isValidating, isSubmitting]);

  const handleGoToError = () => {
    const errorMessage = document.querySelector('[class*="error-message"]');

    if (errorMessage) {
      errorMessage.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
    setIsErrorInfoVisible(false);
  };

  return (
    <Flex
      gap="1rem"
      w="max-content"
      marginLeft="auto"
      justifyContent="flex-end"
      position="sticky"
      bottom=".5rem"
      right=".5rem"
      backgroundColor="transparent"
      paddingTop="1rem"
      zIndex="1"
    >
      {!isValid && isErrorInfoVisible && (
        <Alert status="error" padding=".5rem 1rem">
          <AlertIcon />
          Formularz zawiera błędy.
          <Button onClick={handleGoToError} variant="ghost" colorScheme="red">
            Sprawdź
          </Button>
        </Alert>
      )}
      <Button
        isDisabled={isLoading}
        onClick={handleGoBack}
        leftIcon={<CloseIcon />}
        minW="6rem"
        alignSelf="flex-end"
        backgroundColor="white"
      >
        Anuluj
      </Button>
      <Button
        isLoading={isLoading}
        type="submit"
        leftIcon={<CheckIcon />}
        variant="solid"
        minW="6rem"
        alignSelf="flex-end"
      >
        Zapisz
      </Button>
    </Flex>
  );
};

export default FieldsFooter;
