import { EStatus } from "../methods/useGetComplaints/types";

export const getBadgeProps = (status: string) => {
  switch (status) {
    case EStatus.IN_PROGRESS:
      return { variant: "purple" };
    case EStatus.OPEN:
      return { variant: "purple" };
    case EStatus.APPROVED:
      return { variant: "green" };
    case EStatus.REJECTED:
      return { variant: "red" };
    default:
      return { variant: "gray" };
  }
};
