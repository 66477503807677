// https://api.nanostore.sa-rnd.devguru.co/themis/versions/1.1/#operation/post-orders-complaints-refund

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { TComplaintRefound } from "./types";

const getOrdersComplaintRefoundUrl = ({
  orderId,
  complaintId
}: {
  orderId: string;
  complaintId: string;
}) => `${API_ROOT}/orders/${orderId}/complaints/${complaintId}/refund`;

type TProps = {
  orderId: string;
  complaintId: string;
};

const useSendComplaintsRefound = ({ orderId, complaintId }: TProps) => {
  const url = getOrdersComplaintRefoundUrl({
    orderId,
    complaintId
  });

  const fetch = useFetchRefactored<TComplaintRefound, any>({
    url,
    method: "POST"
  });

  // TODO: https://netguru.atlassian.net/browse/ZN-5670 uncomment and remove mock when BE is ready
  return {
    data: {},
    isLoading: false,
    fetchData: () =>
      new Promise<{ data: {}; error: any }>(resolve =>
        resolve({
          data: {},
          error: null
        })
      )
  };

  // eslint-disable-next-line no-unreachable
  return fetch;
};

export default useSendComplaintsRefound;
