import { Flex, Text } from "@chakra-ui/react";

import { defaultResultsPerPage } from "consts";
import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";

type TProps = { name: string; options?: number[] };

export function PerPage({ name, options = defaultResultsPerPage }: TProps) {
  return (
    <Flex alignItems="center" gap="1rem">
      <FieldCustomSelect
        name={name}
        options={options.map(value => ({
          value,
          label: value.toString()
        }))}
        size="sm"
      />
      <Text fontSize=".8rem">wyników na stronie</Text>
    </Flex>
  );
}
